<template>
  <div class="mb-1">
    <div class="font-weight-semi-bold mb-1 d-flex align-items-center">
      {{ $t('features.features') }}

      <span
        class="text-primary mx-1"
        style="font-size: 12px"
      >
        (0 is unlimited)
      </span>
    </div>

    <div class="pl-1 border-left-secondary border-left-2">
      <b-row>
        <template v-for="(feature, featureIdx) in featureOptions">
          <b-col
            v-if="!hideDevices || feature.slug !== 'device'"
            :key="featureIdx"
            xl="4"
            lg="6"
            md="6"
          >
            <ok-text-input
              :label="getName(feature)"
              is-required
              :rules="`required|min_value:0`"
            >
              <b-input-group
                :append="feature.unit"
                class="input-group-merge"
              >

                <b-form-input
                  :value="getLimit(feature)"
                  @input="setLimit(feature, $event)"
                />
              </b-input-group>
            </ok-text-input>
          </b-col>
        </template>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BInputGroup, BFormInput, BRow, BCol,
} from 'bootstrap-vue'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'
import LocalizationService from '@/services/localization-service'

export default {
  name: 'CustomFeaturesLimit',
  components: {
    OkTextInput,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    hideDevices: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    features: {
      get() { return this.value ?? {} },
      set(val) { this.$emit('input', val) },
    },
    featureOptions() {
      const limitedFeatures = ['items', 'branches', 'languages', 'cloud']
      return this.$store.getters['subscription/features'].filter(feature => limitedFeatures.includes(feature.slug))
    },
  },
  methods: {
    getLimit({ slug, defaultValue }) {
      if (!this.features[slug]) this.features[slug] = { limit: defaultValue }
      return this.features[slug]?.limit
    },
    setLimit({ slug }, val) {
      if (!this.features[slug]) this.features[slug] = { limit: 1 }
      this.features[slug].limit = val
      this.features = { ...this.features }
      // this.$emit('input', this.features)
    },
    getName(feature) {
      return LocalizationService.getTranslateArEn(feature)
    },
  },
}
</script>
