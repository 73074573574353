<template>
  <ok-modal
    :value="value"
    size="lg"
    :title="renewMode ? $t('subscription.renew').toString() : $t('accounts.change-package').toString()"
    title-class="large-modal-title"
    @change="$event ? null : close()"
  >
    <error-alert :error="error" />

    <validation-observer
      ref="form"
      #default="{invalid}"
      tag="div"
    >
      <b-row>
        <b-col lg="9">
          <b-row>
            <b-col md="6">
              <package-select
                v-model="form.package_id"
                :disabled="renewMode"
                :with-free="renewMode"
                :with-trial="renewMode"
                @input="form.features = []"
                @package="setTotalPrice"
              />
            </b-col>

            <b-col md="6">
              <billing-cycle-select-dropdown
                v-model="form.billing"
                :disabled="renewMode"
                show-custom
                @input="setTotalPrice()"
              />
            </b-col>

            <b-col
              v-if="form.paid!==3"
              md="6"
            >
              <price-input
                v-model.trim="form.total_price"
                :label="renewMode ? $t('general.price') : $t('subscription.total-amount')"
              />
            </b-col>

            <b-col
              v-if="form.billing === 2"
              md="6"
            >
              <ok-date-time-picker
                v-model.trim="form.expires_at"
                is-required
                :label="$t('subscription.expiry-date')"
              />
            </b-col>

            <b-col md="12">
              <payment-status-input
                v-model.trim="form.paid"
                :image.sync="image"
              />
            </b-col>
            <b-col
              v-if="customPackage"
              md="12"
            >
              <custom-features-limit
                v-model="customFeatures"
              />
            </b-col>
            <b-col
              v-if="customPackage"
              md="12"
            >
              <package-permissions v-model="form.permissions" />
            </b-col>
            <b-col
              v-if="form.paid===3"
              md="12"
            >
              <ok-text-input
                v-model="form.gift_reason"
                is-required
                :label="$t('subscription.reason')"
                textarea
              />
            </b-col>
            <b-col md="12">
              <ok-text-input
                v-model="form.note"
                :label="$t('accounts.notes')"
                textarea
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="3">
          <div class="font-weight-bold mb-1">
            {{ $t('subscription.current-features') }}
          </div>
          <ul class="list-unstyled">
            <li
              v-for="(feature, featureIdx) in currentPackageBenefits"
              :key="featureIdx"
            >
              - {{ feature.text }}
            </li>
          </ul>
        </b-col>
      </b-row>

      <div class="d-flex justify-content-end align-items-center mt-1">
        <b-button
          size="lg"
          variant="secondary"
          :disabled="invalid || loading"
          @click="submit"
        >
          <b-spinner
            v-if="loading"
            small
          />
          <template v-else>
            {{ renewMode ? $t('subscription.renew') : $t('forms.change') }}
          </template>
        </b-button>
      </div>
    </validation-observer>
  </ok-modal>
</template>

<script>
import {
  BRow, BCol, BButton, BSpinner,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'
import OkModal from '@/@bya3/components/modals/OkModal'
import PriceInput from '@/@bya3/components/inputs/PriceInput'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'
import ErrorAlert from '@/@bya3/components/alerts/ErrorAlert'
import OkDateTimePicker from '@/@bya3/components/inputs/OkDateTimePicker'
import FormsService from '@/services/forms-service'
import ErrorHandler from '@/utils/error-handler'
import { PACKAGE_PERMISSIONS } from '@/constants/features'
import SubscriptionAPIs from '@/api/admin/subscription-api'
import AddonCycleEnum from '@/models/subscription/AddonCycleEnum'
import SubscriptionTypesEnum from '@/models/subscription/SubscriptionTypesEnum'
import BillingCycleSelectDropdown from './BillingCycleSelectDropdown'
import PaymentStatusInput from './PaymentStatusInput'
import PackageSelect from './PackageSelect'
import CustomFeaturesLimit from './CustomFeaturesLimit'
import PackagePermissions from './PackagePermissions'

export default {
  name: 'ChangePackageModal',
  components: {
    PackagePermissions,
    CustomFeaturesLimit,
    OkDateTimePicker,
    PackageSelect,
    ErrorAlert,
    OkTextInput,
    PaymentStatusInput,
    PriceInput,
    BillingCycleSelectDropdown,
    OkModal,
    ValidationObserver,
    BRow,
    BButton,
    BSpinner,
    BCol,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: [Number, String],
      default: null,
    },
    subscription: {
      type: Object,
      default: () => ({}),
    },
    renewMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        user_id: null,
        package_id: null,
        features: [],
        billing: 1,
        paid: 0,
        total_price: null,
        expires_at: '',
        note: '',
        grace_period: 0,
        monthly_price: 50,
        yearly_price: 960,
        permissions: {},
      },
      customFeatures: {},
      image: null,
      loading: false,
      error: null,
    }
  },
  computed: {
    ...mapGetters({
      addons: 'subscription/features',
      packages: 'subscription/packages',
    }),
    minGracePeriod() {
      /*
      const { expired, expires_at: expiresAt } = this.subscription ?? {}
      return expiresAt && expired ? Math.abs(this.$moment().startOf('day').diff(this.$moment(expiresAt), 'day')) : 1
       */
      return 0
    },
    customPackage() {
      return this.form.package_id === 'custom'
    },
    currentPackageBenefits() {
      const FEATURES = ['items', 'branches', 'languages', 'cloud']
      const features = this.subscription?.plan?.features

      if (!features) return []

      const benefits = []
      FEATURES.forEach(featureKey => {
        const feature = features.find(f => f.slug === featureKey)
        if (!feature) return

        const { limit } = feature
        // eslint-disable-next-line no-nested-ternary
        const choice = feature.limit === 0 ? 2 : (feature.limit > 1 ? 1 : 0)

        switch (feature.slug) {
          case 'items': benefits.push({ text: this.$tc('subscription.feature-plans.items', limit === 0 ? 2 : 1, [feature.limit]), slug: 'items' }); break
          case 'branches': benefits.push({ text: this.$tc('subscription.feature-plans.branch', choice, [feature.limit]), slug: 'branches' }); break
          // eslint-disable-next-line no-nested-ternary
          case 'cloud': benefits.push({ text: this.$tc('subscription.feature-plans.cloud', limit === 0 ? 2 : (limit < 1024 ? 0 : 1), [feature.limit >= 1024 ? (feature.limit / 1024).toFixed(0) : feature.limit]), slug: 'cloud' }); break
          case 'languages': benefits.push({ text: this.$tc('subscription.feature-plans.language', limit > 1 || limit === 0 ? 2 : 1, [feature.limit]), slug: 'languages' }); break
          default: break
        }
      })
      return benefits
    },
  },
  watch: {
    subscription() {
      this.checkSubscription()
    },
    minGracePeriod() {
      this.checkExpiration()
    },
  },
  mounted() {
    this.checkSubscription()
    this.checkExpiration()
    this.setPermission()
  },
  methods: {
    setTotalPrice(pkg = null) {
      let pack = pkg
      if (!pack) {
        const index = this.packages.findIndex(p => p.id === this.form.package_id)
        if (index > -1) {
          pack = this.packages[index]
        }
      }
      this.form.total_price = 0
      if (this.form.billing === 1) {
        this.form.total_price = pack?.id ? (pack?.monthly_price ?? 0) : this.form.monthly_price
      } else if (this.form.billing === 0) {
        this.form.total_price = pack?.id ? (pack?.yearly_price ?? 0) : this.form.yearly_price
      }
    },
    setPermission() {
      this.form.permissions = {}
      Object.values(PACKAGE_PERMISSIONS ?? {}).forEach(permission => {
        this.form.permissions[permission] = this.subscription?.package_permissions ? (this.subscription?.package_permissions[permission] ?? true) : true
      })
    },
    checkExpiration() {
      if (this.minGracePeriod > this.form.grace_period) {
        this.form.grace_period = this.minGracePeriod
      }
    },
    checkSubscription() {
      const { subscription } = this
      // eslint-disable-next-line no-nested-ternary
      this.form.billing = (subscription?.is_custom ? 2 : (subscription?.is_monthly ? 1 : 0)) ?? this.form.billing
      if (subscription?.package?.type === SubscriptionTypesEnum.Paid) {
        this.form.package_id = subscription?.package_id ?? this.form.package_id
      }
      this.form.total_price = subscription?.price ?? this.form.total_price

      if (!this.renewMode && subscription?.addons) {
        this.form.features = this.addons.filter(addon => subscription?.addons.findIndex(a => a.slug === addon.slug) >= 0)
      }
    },
    async submit() {
      const valid = await (this.$refs.form.validateWithInfo())
      if (!valid.isValid) {
        this.error = { title: this.$tc('errors.field-invalid', 2), message: ErrorHandler.getValidationErrors(valid.errors) }
        return
      }

      let payload = cloneDeep(this.form)
      payload.user_id = this.userId
      const isCustom = this.form.billing === 2
      const isMonthly = this.form.billing === 1

      if (this.customPackage) {
        payload.features = Object.keys(this.customFeatures).reduce((prev, featureSlug) => {
          const featureId = this.packageFeatures.find(feature => feature.slug === featureSlug)?.id
          if (!featureId) return { ...prev }
          return { ...prev, [featureId]: this.customFeatures[featureSlug] }
        }, {})
        payload.price = payload.total_price
      } else {
        payload.features = payload.features.map(feature => {
          const isBoth = feature.cycle === AddonCycleEnum.BOTH
          let cycle = null
          if (isCustom) cycle = AddonCycleEnum.CUSTOM
          else if (isBoth) cycle = isMonthly ? AddonCycleEnum.MONTHLY : AddonCycleEnum.YEARLY
          else cycle = feature.cycle

          return { id: feature.id, cycle }
        })
      }

      if (this.form.paid && this.image) {
        payload.image = this.image
      }

      if (this.renewMode) {
        payload.package_id = null
        payload.billing = null
        payload.expires_at = null
      } else {
        payload.cost = payload.total_price || payload.price || payload.monthly_price || payload.yearly_price || 0
      }
      if (payload.paid !== 0) {
        delete payload.grace_period
      }
      // eslint-disable-next-line no-underscore-dangle
      payload = FormsService.convertObjectToFormData(payload)

      this.loading = true
      this.error = null
      let api = null
      if (this.renewMode) {
        api = SubscriptionAPIs.renewSubscription(payload)
      } else {
        api = SubscriptionAPIs.changePackage(payload)
      }

      api.then(response => {
        if (response.message) {
          this.$notifySuccess(response.message)
        } else if (this.renewMode) {
          this.$notifySuccess('subscription.subscription-renewed')
        }
        this.$emit('submitted')
        this.close()
      })
        .catch(error => { this.error = error })
        .finally(() => { this.loading = false })
    },
    close() {
      this.error = null
      this.$emit('input', false)
    },
  },
}
</script>
