<template>
  <div class="invoice-view">
    <error-alert :error="error" />
    <validation-observer
      ref="form"
    >
      <b-row class="mt-2">
        <b-col
          md="4"
          class="mb-1"
        >
          <ok-text-input
            v-model="type.ar_name"
            :label="`${$t('fields.name')} (${$t('locales.ar')})`"
            is-required
          />
        </b-col>
        <b-col
          md="4"
          class="mb-1"
        >
          <ok-text-input
            v-model="type.en_name"
            :label="`${$t('fields.name')} (${$t('locales.en')})`"
            is-required
          />
        </b-col>
        <b-col md="4">
          <ok-text-input
            v-model="type.slug"
            :read-only="slugDisabled"
            :label="$t('fields.slug')"
            is-required
          />
        </b-col>
        <b-col
          v-for="label in businessTypesLabels"
          :key="label"
          sm="6"
        >
          <ok-select
            v-model="type.features[label]"
            :options="businessTypesLabelsOptions[label]"
            :label="prettyName(label)"
            is-required
          />
        </b-col>
        <b-col md="12">
          <legend class="col-form-label px-0 pb-2">
            {{ $t('business-type.features') }}
          </legend>
          <b-row class="align-items-center">
            <b-col
              v-for="feature in businessTypesFeatures"
              :key="feature"
              md="4"
              sm="4"
              class="mb-1"
            >
              <b-form-checkbox
                v-model="type.features[feature]"
                class="custom-control-secondary"
                switch
              >
                {{ prettyName(feature) }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import upperFirst from 'lodash/upperFirst'
import cloneDeep from 'lodash/cloneDeep'
import isNil from 'lodash/isNil'
import { ValidationObserver } from 'vee-validate'
import ErrorHandler from '@/utils/error-handler'

import ErrorAlert from '@/@bya3/components/alerts/ErrorAlert'
import BusinessTypesAPI from '@/api/admin/business-types-api'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'
import OkSelect from '@/@bya3/components/inputs/OkSelect'
import { BUSINESS_FEATURES, LABELS_KEYS, LABELS_OPTIONS } from '@/constants/business-types'

export default {
  directives: {
    Ripple,
  },
  components: {
    OkSelect,
    OkTextInput,
    ErrorAlert,
    BFormCheckbox,
    BRow,
    BCol,
    ValidationObserver,
  },
  props: {
    businessType: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      error: null,
      loading: false,
      businessTypesFeatures: Object.values(BUSINESS_FEATURES),
      businessTypesLabels: LABELS_KEYS,
      businessTypesLabelsOptions: LABELS_OPTIONS,
      slugDisabled: false,
      type: {
        features: {},
        ar_name: '',
        en_name: '',
        slug: '',
      },
    }
  },
  computed: {
    editMode() {
      return !!this.businessType?.id
    },
  },
  watch: {
    loading(val) {
      this.$emit('submitting', val)
    },
  },
  async mounted() {
    if (this.editMode) {
      this.getType()
    } else {
      this.reset()
    }
  },
  methods: {
    initFeaturesKeys() {
      const label = this.editMode ? 'businessType' : 'type'
      this.businessTypesFeatures.forEach(feature => {
        this.type.features[feature] = isNil(this[label].features[feature]) ? true : this[label].features[feature]
      })
      this.businessTypesLabels.forEach(labelKey => { (this[label].features[labelKey] = this[label].features[labelKey] ?? '') })
    },
    getType() {
      this.type = {
        ar_name: this.businessType.ar_name,
        en_name: this.businessType.en_name,
        slug: this.businessType.slug,
        features: {},
      }
      this.initFeaturesKeys()
      if (this.type.slug) {
        this.slugDisabled = true
      }
    },
    reset() {
      this.type = {
        features: {},
        ar_name: '',
        en_name: '',
        slug: '',
      }
      this.initFeaturesKeys()
    },
    async save() {
      this.error = null
      const valid = await (this.$refs.form.validateWithInfo())
      if (!valid.isValid) {
        this.error = { title: this.$tc('errors.field-invalid', 2), message: ErrorHandler.getValidationErrors(valid.errors) }
      } else {
        this.loading = true
        const form = cloneDeep(this.type)
        this.businessTypesFeatures.forEach(feature => { (form.features[feature] = isNil(form.features[feature]) ? false : form.features[feature]) })
        form.features = JSON.stringify(form.features)
        if (this.editMode) form.id = this.businessType.id
        await BusinessTypesAPI.process(form).then(response => {
          this.$notifySuccess(response.message)
          this.$emit('updated')
        })
          .catch(err => { this.error = err })
          .finally(() => {
            this.loading = false
          })
      }
    },
    prettyName(name) {
      return upperFirst(name.replace('_', ' '))
    },
  },
}
</script>
