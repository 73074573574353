<template>
  <v-select
    :value="value"
    class="business-types-select"
    :class="{'is-invalid': state === false, 'select-size-lg': size === 'lg', 'select-size-sm': size === 'sm'}"
    :dir="isRTL ? 'rtl' : 'ltr'"
    :options="options"
    label="name"
    :clearable="clearable"
    :reduce="t => t.id"
    :loading="loading"
    max-height="100px"
    @input="$emit('input', $event)"
  />
</template>

<script>
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import LocalizationService from '@/services/localization-service'

export default {
  name: 'BusinessTypesSelect',
  components: { vSelect },
  props: {
    value: {
      type: Number,
      default: null,
    },
    selectRestaurant: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      options: [],
    }
  },
  computed: {
    ...mapGetters({
      businessTypes: 'businessTypes/types',
    }),
    Locale() {
      return this.$i18n.locale
    },
  },
  watch: {
    Locale() {
      this.getBusinessTypes(true)
    },
  },
  async mounted() {
    await this.getBusinessTypes(false)
  },
  methods: {
    async getBusinessTypes(force = false) {
      this.loading = true
      await this.$store.dispatch('businessTypes/FETCH_TYPES', { force }).then(types => {
        if (!this.selectRestaurant || this.value) return
        const restaurantId = types.find(type => type.slug === 'restaurant')?.id
        this.$emit('input', restaurantId)
      }).catch(e => this.$notifyError(e)).finally(() => { this.loading = false })
      this.options = this.businessTypes.map(b => ({ ...b, name: LocalizationService.getTranslateArEn(b) }))
    },
  },
}
</script>
