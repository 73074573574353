<template>
  <div
    v-if="!changingBranch"
    class="setup-wizard mx-auto"
    :class="{'hide-navigation': hideNavigation, 'mt-xl-3': !hideNavigation, 'content-center': hideNavigation && currentTab.center, 'fluid': currentTab.fluid, 'tab-content-w-100': (currentTab.maxWidth && hideNavigation), narrow: currentTab.narrowCard}"
  >
    <form-wizard
      ref="wizard"
      color="#ecae21"
      :title="null"
      :subtitle="null"
      :layout="['lg', 'xl'].includes(currentBreakPoint) ? 'vertical' : 'horizontal'"
      :start-index="0"
      :style="currentTab.maxWidth && hideNavigation ? {maxWidth: currentTab.maxWidth} : {}"
      class="vertical-steps steps-transparent mx-auto"
      :hide-buttons="true"
      @on-complete="handleComplete"
      @on-change="handleTabChange"
    >

      <template v-for="(tab, idx) in filteredTabs">
        <tab-content
          :key="idx"
          :title="$t(tab.title)"
          :icon="tab.icon"
          :before-change="handleBeforeTabChange"
          :lazy="true"
        >
          <setup-wizard-tab
            :title="tab.cardTitle || tab.title"
            :description="tab.description"
            :next-button-title="getNextButtonTitle(tab, idx)"
            :show-previous="(!inEditMode && idx > 0) && !tab.hidePrev"
            :next-button-class="tab.nextButtonClass || ''"
            :loading="loading || forceLoading"
            :hide-next="(tab.hideNext || disabled || (inEditMode && tab.hideSaveInEditMode)) && !forceShowNext"
            :show-skip="(!inEditMode && disabled && !!$listeners && !!$listeners.skip) || tab.canSkip"
            :skip-button-title="tab.skipButtonTitle ? $t(tab.skipButtonTitle) : null"
            :next-handler="() => handleNext(`tab-${idx}`, idx)"
            :prev-handler="prevTab"
            :skip-handler="handleSkip"
            @next="handleNext(`tab-${idx}`, idx)"
            @complete="handleComplete"
            @prev="prevTab"
            @skip="handleSkip"
          >
            <template
              #actions="slotProps"
            >
              <!-- @slot actions beside card title -->
              <slot
                name="actions"
                v-bind="{...slotProps, tab: idx, isLastStep: idx === (filteredTabs.length - 1)}"
              >
                <portal-target
                  v-if="tab.customActions"
                  :name="`${tab.id || idx}-tab-actions`"
                />
              </slot>
            </template>

            <template #alert>
              <!-- @slot section before rendering step component -->
              <slot name="tab-header" />

              <error-alert
                :error="error"
                dismissible
              />
            </template>
            <div :class="{'disabled-content': disabled}">
              <component
                :is="tab.component"
                :ref="`tab-${idx}`"
                :active="currentIndex === idx"
                :edit-mode="inEditMode"
                v-bind="tab.props || {}"
                @goTo="changeTab(currentIndex,$event)"
                @next="handleNext(`tab-${idx}`, idx, $event)"
                @prev="prevTab"
                @append-skip-steps="handleAppendSkipSteps"
                @update:skip-steps="skipSteps = $event"
              />
            </div>
          </setup-wizard-tab>
        </tab-content>
      </template>

    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { mapGetters } from 'vuex'
import { BAlert } from 'bootstrap-vue'
import SetupWizardTab from '@/@bya3/components/wizards/setup-wizard/SetupWizardTab'
import ErrorHandler from '@/utils/error-handler'
import ErrorAlert from '@/@bya3/components/alerts/ErrorAlert'

export default {
  name: 'SetupWizard',
  components: {
    ErrorAlert,
    SetupWizardTab,
    FormWizard,
    TabContent,
    BAlert,
  },
  props: {
    /**
     * To indicate that wizard on edit mode
     */
    startFrom: {
      type: [Number],
      default: 0,
    },
    /**
     * To indicate that wizard on edit mode
     */
    editMode: {
      type: [Boolean, Number],
      default: false,
    },
    /**
     * Step or tabs
     */
    tabs: {
      type: Array,
      required: true,
      default: () => [],
    },
    /**
     * To hide or enable navigation menu
     */
    navigation: {
      type: Boolean,
      default: true,
    },
    /**
     * Steps to be skipped at start of wizard
     */
    initSkippedSteps: {
      type: Array,
      default: () => [],
    },
    /**
     * Disable wizard
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Force show navigation in case create mode or one step wizard
     */
    forceShowNavigation: {
      type: Boolean,
      default: false,
    },
    /**
     * In case edit mode
     */
    forceShowNext: {
      type: Boolean,
      default: false,
    },
    /**
     * To show spinner in the next button
     */
    forceLoading: {
      type: Boolean,
      default: false,
    },
    /**
     * To ask use the user to submit if there are changes and in create mode (edit mode will be always true)
     */
    checkUnsavedChangedCreateMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      startIndex: 0,
      currentIndex: 0,
      error: null,
      skipSteps: [],
      changingBranch: false,
      inEditMode: false,
    }
  },
  computed: {
    ...mapGetters({
      isStepPassed: 'setup/isStepPassed',
      currentBreakPoint: 'app/currentBreakPoint',
    }),
    filteredTabs() {
      return this.tabs.filter(tab => !(typeof tab.hide === 'function' ? tab.hide() : tab.hide) && ((!this.inEditMode && !this.skipSteps.includes(tab.id) && !tab.hideInCreateMode) || (this.inEditMode && !tab.hideInEditMode)))
    },
    hideNavigation() {
      return (!this.inEditMode || this.filteredTabs.length <= 1 || !this.navigation) && !this.forceShowNavigation
    },
    currentTab() {
      return this.filteredTabs?.[this.currentIndex] ?? {}
    },
  },
  watch: {
    currentBranchId() {
      this.changingBranch = true
      this.$nextTick(() => { this.changingBranch = false })
    },
    initSkippedSteps() {
      this.skipSteps = this.initSkippedSteps ?? []
    },
    editMode() {
      setTimeout(() => { this.inEditMode = this.editMode }, 1000)
    },
    inEditMode(inEditMode) {
      if (inEditMode) {
        this.$nextTick(() => this.activateAll())
      }
    },
    currentIndex(currentIndex) {
      /**
       * update current step index
       * @property {number} currentIndex
       */
      this.$emit('update:index', currentIndex)
    },
  },
  created() {
    this.inEditMode = this.editMode
  },
  mounted() {
    const routeStep = parseInt(this.$route.query.step ?? '', 10)
    this.inEditMode = this.editMode

    if (this.inEditMode) {
      this.activateAll()
    } else {
      this.skipSteps = this.initSkippedSteps
      const slugs = this.filteredTabs.map(tab => tab.slug)

      for (let i = 0; i < slugs.length; i += 1) {
        if (slugs[i] && !this.isStepPassed(slugs[i])) {
          this.startIndex = i
          break
        }
      }
    }

    if (!Number.isNaN(routeStep)) {
      this.startIndex = this.inEditMode ? routeStep : Math.min(this.startIndex, routeStep)
    }

    if (this.startIndex !== 0) {
      this.changeTab(0, this.startIndex)
    }

    this.currentIndex = this.startIndex
  },
  methods: {
    reset() {
      if (this.$refs.wizard?.reset) {
        this.$refs.wizard.reset()
      }
    },
    activateAll() {
      if (this.$refs.wizard) {
        this.$refs.wizard.activateAll()
      }
    },
    nextTab() {
      this.$refs.wizard.nextTab()
    },
    prevTab() {
      this.$refs.wizard.prevTab()
    },
    changeTab(oldIndex, newIndex) {
      this.$refs.wizard.changeTab(oldIndex, newIndex)
    },
    getNextButtonTitle(tab) {
      const saveKey = 'forms.save'
      const nextKey = 'forms.next'

      if (!this.inEditMode && tab.nextButtonTitleWizardMode) {
        return this.$t(tab.nextButtonTitleWizardMode)
      }

      return this.$t(tab.nextButtonTitle || (this.inEditMode ? saveKey : nextKey))
    },
    handleNext(refId, idx, withoutSubmit = false) {
      const isLastTab = idx === this.filteredTabs.length - 1
      const ref = this.$refs[refId]?.[0] ?? this.$refs[refId]

      if (!ref?.submit || withoutSubmit === true) {
        this.error = null
        if (isLastTab) {
          /**
           * wizard completed
           */
          this.$emit('complete')
        } else {
          this.nextTab()
        }
        return
      }

      const { inEditMode } = this

      this.loading = true
      Promise.resolve(ref.submit())
        .then(response => {
          this.error = null
          if (inEditMode) {
            this.loading = false
            this.$emit('finished')
            return
          }

          if (response === 'STOP') {
            this.loading = false
            return
          }

          if (response === 'REDIRECT') {
            this.loading = true
            return
          }
          if (isLastTab) {
            this.handleComplete()
          } else {
            this.nextTab()
          }
          this.loading = false
        })
        .catch(e => {
          this.error = (e?.message || e) ? ErrorHandler.getErrorDetails(e) : null
          this.loading = false
        })
    },
    async handleTabChange(prevIndex, nextIndex) {
      this.error = null

      if (nextIndex < 0 || prevIndex < 0) return

      this.currentIndex = nextIndex
      const prevSlug = this.filteredTabs[prevIndex]?.slug

      if (prevIndex < nextIndex) {
        this.passStep(prevSlug)
      } else if (this.inEditMode && nextIndex < prevIndex) {
        try {
          await this.checkUnsavedChanges(prevIndex)
        } catch (e) {
          this.changeTab(nextIndex, prevIndex)
          this.error = e
        }
      }
    },
    handleComplete() {
      const slug = this.filteredTabs[this.currentIndex]?.slug
      this.passStep(slug)
      /**
       * wizard completed
       */
      this.$emit('complete')
      this.$nextTick(() => this.activateAll())
    },
    async handleSkip() {
      try {
        const { slug } = this.currentTab
        if (slug) {
          this.loading = true
          await this.$store.dispatch('setup/SEND_PASS_BRANCH_WIZARD', slug)
          this.passStep(slug)
        }
        /**
         * wizard skipped
         */
        this.$emit('skip')
      } catch (e) {
        this.error = e?.message ? ErrorHandler.getErrorDetails(e) : null
      } finally {
        this.loading = false
      }
    },
    passStep(slug) {
      if (!slug) return

      this.$store.dispatch('setup/PASS_STEP', slug)
    },
    handleBeforeTabChange() {
      return new Promise((resolve, reject) => this.checkUnsavedChanges()
        .then(() => resolve(true))
        .catch(e => {
          reject(e)
        }))
    },
    async checkUnsavedChanges(index, leavingRoute) {
      if (!this.inEditMode && !this.checkUnsavedChangedCreateMode) return true

      const refIdx = index ?? this.currentIndex
      const stepRef = this.$refs[`tab-${refIdx}`]?.[0]

      await new Promise(resolve => setTimeout(() => resolve(true), 50)) // sleep for 50ms to apply if submit accords

      if (!stepRef || !stepRef?.submit || !stepRef?.checkUnsavedChanges || !stepRef.checkUnsavedChanges(leavingRoute)) return true

      this.error = null

      const confirmed = await this.$okConfirm(this.$t('forms.some-changes-unsaved'), {
        confirmButtonText: this.$t('forms.save'),
        cancelButtonText: this.$t('forms.discard'),
        showCancel: true,
        disableOutsideClick: true,
        preConfirm: () => Promise.resolve(stepRef.submit(leavingRoute)).catch(e => { this.error = ErrorHandler.getErrorDetails(e) }),
      })

      if (this.error) {
        throw this.error
      }

      if (!confirmed && stepRef?.reset) {
        stepRef.reset()
      }

      return confirmed
    },
    handleAppendSkipSteps(steps) {
      if (Array.isArray(steps)) {
        this.skipSteps.push(...steps)
      } else {
        this.skipSteps.push(steps)
      }
    },
    getTabsRefs() {
      return this.tabs.reduce((prev, tab, idx) => [...prev, (this.$refs[`tab-${idx}`]?.[0])], []).filter(Boolean)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';

</style>
