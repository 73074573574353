<template>
  <div>
    <ok-table
      ref="datatable"
      :title="$t('invoice.invoices')"
      :config="config"
    >
      <template
        #type-column="{props}"
      >
        <b-badge
          class="my-0"
          :variant="(props.row.for_package)?'dark':'warning'"
        >
          {{ $t('invoice.'+((props.row.for_package)?'plan':'feature')) }}
        </b-badge>
      </template>
      <template
        #paid-column="{props}"
      >
        <b-badge
          class="my-0"
          :variant="props.row.paid?'success':'danger'"
        >
          {{ $t('general.'+(props.row.paid?'yes':'no')) }}
        </b-badge>
      </template>
    </ok-table>

  </div>
</template>

<script>
import { BBadge } from 'bootstrap-vue'
import OkTable from '@/@bya3/components/tables/ok-table/OkTable'
import InvoicesTableConfig from './config/invoices-table-config'

export default {
  components: {
    OkTable,
    BBadge,
  },
  data() {
    return {
      config: InvoicesTableConfig,
    }
  },
}
</script>
