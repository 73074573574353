<template>
  <div>
    <fetching-data-spinner
      :error="error"
      :loading="fetching"
      :loading-title="$t('statistics.preparing-statistics')"
      enable-retry
      @retry="fetchStatistics"
    />

    <div v-show="!fetching && !error && hasPermission">
      <statistics-range
        v-model="range"
        class="mb-1"
        @custom-period="updateCustomRange($event)"
      />

      <b-row class="mb-1">
        <b-col
          v-for="(count, countIdx) in counts"
          :key="countIdx"
          :xl="count.cols"
          lg="4"
          md="12"
        >
          <statistics-count-card v-bind="count" />
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col
          :md="6"
        >
          <statistics-grouped-counts-card v-bind="incomeCounts" />
        </b-col>
        <b-col
          lg="12"
        >
          <packages-table
            :items="statistics.packages"
            title="packages-by-pages"
          />
        </b-col>
      </b-row>
    </div>

  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import FetchingDataSpinner from '@/@bya3/components/utils/FetchingDataSpinner'
import AdminStatisticsApi from '@/api/admin/statistics'
import PackagesTable from '@/views/admin/statistics/tables/plans/PackagesTable'
import AdminPermissions from '@/constants/admin-permissions'
import StatisticsRange from './components/StatisticsRange'
import StatisticsCountCard from './components/StatisticsCountCard'
import StatisticsGroupedCountsCard from './components/StatisticsGroupedCountsCard'

const USD_ICON = require('@/assets/images/usd-circle.svg')

export default {
  name: 'StatisticsView',
  components: {
    PackagesTable,
    FetchingDataSpinner,
    StatisticsGroupedCountsCard,
    StatisticsCountCard,
    StatisticsRange,
    BRow,
    BCol,
  },
  data() {
    return {
      range: {
        fromDate: this.$moment('2020-12-01').format('YYYY-MM-DD'),
        toDate: this.$moment().format('YYYY-MM-DD'),
      },
      last: '2years',
      fetching: false,
      error: null,
      statistics: {},
    }
  },
  computed: {
    counts() {
      const {
        // visitors,
        activePages, expiredPages, freePages, oneTimePages, current_revenue_fb,
      } = this.statistics
      const titleIcon = ''
      return [
        {
          cols: 3,
          'title-icon': titleIcon,
          feather: 'CheckCircleIcon',
          variant: 'light-success',
          title: this.$t('statistics.active-pages', { price: current_revenue_fb }),
          value: activePages,
        },
        {
          cols: 3,
          'title-icon': titleIcon,
          feather: 'DeleteIcon',
          variant: 'light-danger',
          title: this.$t('statistics.expired-pages'),
          value: expiredPages,
        },
        {
          cols: 3,
          'title-icon': titleIcon,
          feather: 'GiftIcon',
          title: this.$t('statistics.free-pages'),
          value: freePages,
        },
        {
          cols: 3,
          'title-icon': titleIcon,
          feather: 'InfoIcon',
          variant: 'light-warning',
          title: this.$t('statistics.onetime-pages'),
          value: oneTimePages,
        },
      ]
    },
    incomeCounts() {
      const { revenue_fb } = this.statistics
      const { total_amount, paid, unpaid } = revenue_fb ?? { total_amount: 0, paid: 0, unpaid: 0 }
      const append = 'ل.س'
      const titleIcon = ''
      return {
        titleIcon,
        icon: USD_ICON,
        title: this.$t('statistics.income'),
        counts: [{
          value: parseFloat(unpaid).toFixed(1),
          append,
          description: this.$t('invoice.unpaid-invoices'),
        }, { value: parseFloat(paid).toFixed(1), append, description: this.$t('statistics.paid') }],
        totalText: this.$t('statistics.total-revenue'),
        total: total_amount.toFixed(0),
        totalAppend: append,
      }
    },
    countries() {
      const { countries } = this.statistics ?? []
      const data = {}
      countries.forEach(country => {
        data[country.country] = country.count
      })
      return data
    },
    hasPermission() {
      return this.$store.getters['auth/isSuperAdminHasPermission'](AdminPermissions.SUPER_ADMIN)
    },
  },
  watch: {
    range() {
      this.fetchStatistics()
    },
    last(val) {
      if (val) this.fetchStatistics()
    },
  },
  mounted() {
    this.fetchStatistics()
  },
  methods: {
    updateRange(range) {
      this.range = range
    },
    updateCustomRange(last) {
      this.last = last
    },
    fetchStatistics() {
      if (!this.hasPermission) return
      this.fetching = true
      this.error = null
      const data = this.last ? { last: this.last } : this.range

      AdminStatisticsApi.statistics(data)
        .then(statistics => {
          this.statistics = statistics
        })
        .catch(error => {
          this.error = error
        })
        .finally(() => {
          this.fetching = false
        })
    },
  },
}
</script>
<style lang="scss">
.superadmin-items-card {
  padding: 37px 33px !important;

  .title {
    font-size: 25px;
    font-weight: 700;
    line-height: 1.2;
    color: var(--purple);
    margin-bottom: 25px;
  }
}

.superadmin-table {
  max-height: 400px;
  overflow-y: scroll;
  position: relative
}
</style>
