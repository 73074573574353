import OkTableConfigType from '@/@bya3/components/tables/ok-table/OkTableConfigType'
import { API_URL } from '@/constants/config'
import LocalizationService from '@/services/localization-service'
import store from '@/store'

const AccountsTableConfig: OkTableConfigType = {
  mode: 'remote',
  url: `${API_URL}/admin/users`,
  rowsPath: 'users',
  rowClickable: true,
  chooser: true,
  chooserMax: 7,
  columns: [
    {
      label: 'forms.actions',
      field: 'actions',
      $isDisabled: true,
    },
    {
      label: 'fields.id',
      field: 'id',
    },
    {
      label: 'business.name',
      field: 'business_name',
    },
    {
      label: 'fields.email',
      field: 'email',
    },
    {
      label: 'fields.phone-number',
      field: 'phone',
    },
    {
      label: 'invoice.plan',
      field: 'package',
    },
    {
      label: 'invoice.expires-at',
      field: 'expires_at',
    },
    {
      label: 'fields.created-at',
      field: 'created_at',
      sortable: true,
    },
    {
      label: 'accounts.full-name',
      field: 'name',
      hidden: true,
    },
    {
      label: 'orders.payment-status',
      field: 'paid',
      hidden: true,
      formatFn: (val: number) => LocalizationService.translate(`general.${val ? 'paid' : 'unpaid'}`),
    },
    {
      label: 'accounts.business-type',
      field: 'business_type',
      formatFn: (value: any) => LocalizationService.getTranslate(value),
      hidden: true,
    },
    {
      label: 'accounts.powered-by',
      field: 'remove_power_by',
      hidden: true,
      formatFn: (val: number) => LocalizationService.translate(`general.${val ? 'yes' : 'no'}`),
    },
  ],
  paginationOptions: {
    enabled: true,
  },
  sortOptions: {
    enabled: true,
  },
  actions: [
  ],
  filters: [
    {
      label: 'fields.general-search',
      query: 'search',
      colLength: 3,
    },
    {
      label: 'fields.name',
      query: 'name',
      colLength: 3,
    },
    {
      label: 'business.name',
      query: 'business_name',
      colLength: 3,
    },
    {
      label: 'store.domain',
      query: 'domain',
      colLength: 3,
    },
    {
      label: 'store.business-type',
      query: 'business_type',
      colLength: 3,
      type: 'select',
      options: () => store.getters['businessTypes/types'].map((type: any) => ({ id: type.id, name: LocalizationService.getTranslateArEn(type) })),
      optionsLabel: { keyLabel: 'name', valueLabel: 'id', translate: false },
    },
    {
      label: 'fields.email',
      query: 'email',
      colLength: 3,
    },
    {
      label: 'fields.phone-number',
      query: 'phone_number',
      colLength: 3,
    },
    {
      label: 'invoice.plan',
      query: 'package',
      colLength: 3,
      type: 'select',
      multiple: true,
      options: () => store.getters['subscription/packages'].map((plan: any) => ({ id: plan.id, name: LocalizationService.getTranslateArEn(plan) })).concat([{ name: 'Custom', id: 0 }]),
      optionsLabel: { keyLabel: 'name', valueLabel: 'id', translate: false },
    },
    {
      label: 'invoice.expires-at',
      query: 'expire',
      colLength: 3,
      type: 'range',
      range_type: 'date',
    },
    {
      label: 'fields.created-at',
      query: 'created',
      colLength: 3,
      type: 'range',
      range_type: 'date',
    },
  ],
}

export default AccountsTableConfig
