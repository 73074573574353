<template>
  <div class="column-chooser">
    <vue-multiselect
      v-model="selected"
      selected-label="✅"
      deselect-label="❌"
      select-label="✔"
      :close-on-select="false"
      :options="columns"
      track-by="field"
      :allow-empty="false"
      multiple
      :limit="0"
      :limit-text="count => `${count} columns`"
      @input="checkMax"
    >
      <template
        slot="option"
        slot-scope="{ option }"
      >
        {{ $t(option.label) }}
      </template>
    </vue-multiselect>
  </div>
</template>

<script>
import(/* webpackChunkName: "multiselect" */ 'vue-multiselect/dist/vue-multiselect.min.css')

export default {
  name: 'ColumnSelector',
  components: {
    VueMultiselect: () => import(/* webpackChunkName: "multiselect" */ 'vue-multiselect'),
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    max: {
      type: Number,
      default: 30,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: [],
    }
  },
  watch: {
    value(value) {
      this.selected = value
    },
  },
  mounted() {
    this.$nextTick(() => { this.selected = this.value })
  },
  methods: {
    checkMax(selected) {
      this.$emit('input', selected)
      if (this.showColumns?.length > (this.max - 2)) {
        // this.$notifyError(`You can display only ${this.config.chooserMax} columns`)
        // eslint-disable-next-line no-param-reassign,array-callback-return
        this.columns.map(col => { col.$isDisabled = (!selected.some(col2 => col2.field !== col.field) && col.field !== 'actions') })
      }
    },
  },
}
</script>
