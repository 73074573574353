<template>
  <ok-modal
    :value="value"
    size="lg"
    dialog-class="tables-modal"
    :title="$t('help.'+(editMode?'edit-category':'add-category'))"
    @change="$emit('input',false)"
  >
    <div>
      <error-alert
        v-if="error"
        :error="error"
      />
      <validation-observer ref="form">
        <b-row>
          <b-col md="4">
            <ok-text-input
              v-model="form.ar_name"
              :label="`${$t('help.question')} (Arabic)`"
              is-required
            />
          </b-col>
          <b-col md="4">
            <ok-text-input
              v-model="form.en_name"
              :label="`${$t('help.question')} (English)`"
            />
          </b-col>
          <b-col md="4">
            <ok-text-input
              v-model="form.order"
              is-required
              :label="$t('help.order')"
            />
          </b-col>
        </b-row>
        <div>
          <h4>{{ $t('menu.subcategories') }}</h4>
          <b-row
            v-for="(subcategory,index) in form.subcategories"
            :key="index"
            class="align-items-center"
          >
            <b-col md="4">
              <ok-text-input
                v-model="subcategory.ar_name"
                :label="`${$t('excel.subcategories')} (Arabic)`"
                is-required
              />
            </b-col>
            <b-col md="4">
              <ok-text-input
                v-model="subcategory.en_name"
                :label="`${$t('excel.subcategories')} (English)`"
              />
            </b-col>
            <b-col md="2">
              <ok-text-input
                v-model="subcategory.order"
                is-required
                :label="$t('help.order')"
              />
            </b-col>
            <b-col md="2">
              <b-button
                variant="danger"
                class="mt-1"
                @click="removeSubcategory(index)"
              >
                <feather-icon icon="TrashIcon" /></b-button>
            </b-col>
          </b-row>
          <b-button
            varaint="succeess"
            @click="addSubcategory"
          >
            {{ $t('help.add-subcategory') }}
          </b-button>
        </div>
      </validation-observer>
    </div>
    <template #actions>
      <div class="d-flex justify-content-end align-items-center">

        <b-button
          variant="outline-dark"
          class="mr-50"
          @click="reset(), $emit('input',false)"
        >
          {{ $t('forms.cancel') }}
        </b-button>
        <b-button
          variant="dark"
          :disabled="loading"
          @click="submit"
        >
          {{ $t('forms.submit') }}
        </b-button>
      </div>
    </template>
  </ok-modal>
</template>

<script>
import { BButton, BRow, BCol } from 'bootstrap-vue'
import cloneDeep from 'lodash/cloneDeep'
import { ValidationObserver } from 'vee-validate'
import OkModal from '@/@bya3/components/modals/OkModal'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'
import HelpAPI from '@/api/admin/help-api'
import ErrorHandler from '@/utils/error-handler'
import ErrorAlert from '@/@bya3/components/alerts/ErrorAlert'

export default {
  name: 'ProcessGuideCategoryModal',
  components: {
    ErrorAlert,
    ValidationObserver,
    OkTextInput,
    OkModal,
    BButton,
    BRow,
    BCol,
  },
  props: {
    category: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      error: null,
      form: {},
    }
  },
  computed: {
    editMode() {
      return this.category?.id ?? false
    },
  },
  watch: {
    category() {
      this.setCategory()
    },
  },
  mounted() {
    this.setCategory()
  },
  methods: {
    setCategory() {
      this.reset()
      if (this.editMode) {
        this.form = { ...this.category }
      }
    },
    reset() {
      this.form = {
        en_name: '',
        subcategories: [],
        ar_name: '',
        order: 0,
      }
    },
    removeSubcategory(index) {
      this.form.subcategories.splice(index, 1)
    },
    addSubcategory() {
      this.form.subcategories.push({
        ar_name: '', en_name: '', parent_id: this.form?.id, order: 0,
      })
    },
    async submit() {
      this.error = null
      const valid = await (this.$refs.form.validateWithInfo())
      if (!valid.isValid) {
        this.error = { title: this.$tc('errors.field-invalid', 2), message: ErrorHandler.getValidationErrors(valid.errors) }
      } else {
        this.loading = true
        const form = cloneDeep(this.form)
        if (this.editMode) {
          form.id = this.category.id
        }
        await HelpAPI.edit(form)
          .then(response => {
            this.$notifySuccess(response.message)
            this.reset()
            this.$emit('processed')
          })
          .catch(err => {
            this.error = err
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
  },
}
</script>
