<template>
  <div>
    <ok-table
      ref="datatable"
      :config="config"
      :title="$t('menu.users')"
      :show-columns="showColumns"
    >
      <template #buttons>
        <column-selector
          v-if="config.chooser"
          v-model="showColumns"
          :value="selectedColumns"
          :columns="config.columns"
          :max="config.chooserMax"
        />
        <b-button
          variant="info"
          size="md"
          class="d-flex align-items-center ml-1"
          @click="exportAccounts"
        >
          <FeatherIcon
            icon="DownloadIcon"
            class="mr-50"
          />
          <span class="align-middle"> {{ $t('forms.export') }}</span>
        </b-button>
        <b-button
          variant="dark"
          size="md"
          :to="{name:'admin:accounts-add'}"
          class="d-flex align-items-center mx-1"
        >
          <FeatherIcon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle"> {{ $t('accounts.new-user-account') }}</span>
        </b-button>
      </template>
      <template
        #package-column="{ props }"
      >
        <b-badge
          v-if="props.row.plan"
          variant="warning"
        >
          {{ getPlanName(props.row.plan) }}
        </b-badge>
        <span v-else> - </span>
      </template>
      <template
        #business_name-column="{ props }"
      >
        <b-badge :variant="props.row.phone?'success':'danger'">
          {{ props.row.business_name }}
        </b-badge>
      </template>
      <template
        #actions-column="{ props }"
      >
        <span
          v-b-tooltip.bottom="{ title: 'Impersonate' }"
          class="cursor-pointer"
          style="margin: 0 5px"
          @click="impersonate(props.row)"
        >
          <FeatherIcon icon="VideoIcon" />
        </span>
        <b-button
          v-b-tooltip.html.focus.bottom="getUserInfo(props.row)"
          variant="link"
          class="cursor-pointer btn-icon p-0 text-body"
          style="margin: 0 5px"
        >
          <FeatherIcon icon="EyeIcon" />
        </b-button>
        <router-link
          :to="{ name: 'admin:accounts-add', params: { id: props.row.id } }"
          class="text-body"
          style="margin: 0 5px"
        >
          <FeatherIcon
            v-b-tooltip.hover.bottom="$t('forms.edit')"
            icon="EditIcon"
          />
        </router-link>
      </template>
    </ok-table>
  </div>
</template>

<script>
import {
  VBTooltip, BButton, BBadge,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import ColumnSelector from '@/@bya3/components/tables/ok-table/ColumnSelector'
import ImpersonateService from '@/services/impersonate-service'
import AccountsAPI from '@/api/admin/users'
import LocalizationService from '@/services/localization-service'
import AccountsTableConfig from './config/users-table-config'

export default {
  components: {
    ColumnSelector,
    BButton,
    BBadge,
    OkTable: () => import(/* webpackChunkName: "ok-table" */ '@/@bya3/components/tables/ok-table/OkTable'),
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  data() {
    return {
      config: AccountsTableConfig,
      showNotesModal: false,
      selectedAccount: {},
      showColumns: [],
      selectedColumns: [],
      cloneModal: false,
    }
  },
  async mounted() {
    await this.$store.dispatch('businessTypes/FETCH_TYPES')
    this.$nextTick(() => {
      this.showColumns = this.config.columns.filter(col => col?.hidden !== true)
      this.selectedColumns = [...this.showColumns]
    })
    await this.$store.dispatch('pricing/FETCH_PRICING')
  },
  methods: {
    refreshTable() {
      this.selectedAccount = {}
      this.$refs.datatable.loadItems()
    },
    openAccountModal(row) {
      this.showNotesModal = true
      this.selectedAccount = row
    },
    getUserInfo(user) {
      return user.name
    },
    exportAccounts() {
      AccountsAPI.exportAccounts(this.$refs.datatable.$refs.filters.$data.queries)
        .catch(e => this.$notifyError(e))
    },
    getText(row, attr) {
      return row[attr] ?? '-'
    },
    impersonate(user) {
      AccountsAPI.impersonate(user.id)
        .then(({ access_token }) => {
          const token = access_token ?? ''
          const baseUrl = `${window.location.protocol}//${window.location.host}?access_token=${token}`
          ImpersonateService.openWindow(baseUrl, '_blank', 'width=1200,height=800')
        })
        .catch(err => this.$notifyError(err))
    },
    getPlanName(plan) {
      return LocalizationService.getTranslateArEn(plan)
    },
  },
}
</script>
