<template>
  <ok-modal
    :value="value"
    size="lg"
    dialog-class="tables-modal"
    :title="$t('help.'+(editMode?'edit-section':'add-section'))"
    @change="$emit('input',false)"
  >
    <div>
      <error-alert
        v-if="error"
        :error="error"
      />
      <validation-observer ref="form">
        <ok-text-input
          is-required
          :label="$t('excel.subcategories')"
        >
          <guide-category-select
            v-model="form.category_id"
            only-child
          />
        </ok-text-input>
        <ok-text-input
          v-model="form.order"
          is-required
          :label="$t('help.order')"
        />
        <b-tabs>
          <b-tab
            title="Arabic"
          >
            <ok-text-input
              v-model="form.ar_title"
              dir="rtl"
              :label="`${$t('help.section-title')} (Arabic)`"
              is-required
            />
            <pre>
            <code dir="ltr">
              &lt;ms-card&gt;السلام&lt;/ms-card&gt;
              &lt;ms-badge&gt;السلام&lt;/ms-badge&gt;
              &lt;ms-alert type=&quot;warning&quot; icon=&quot;warning&quot; &gt;السلام&lt;/ms-alert&gt;
              &lt;ms-alert type=&quot;primary&quot; icon=&quot;primary&quot; &gt;السلام&lt;/ms-alert&gt;
              &lt;ms-alert type=&quot;success&quot; icon=&quot;success&quot; &gt;السلام&lt;/ms-alert&gt;
              &lt;ms-alert type=&quot;info&quot; icon=&quot;info&quot; &gt;السلام&lt;/ms-alert&gt;
              &lt;ms-alert type=&quot;danger&quot; icon=&quot;danger&quot; &gt;السلام&lt;/ms-alert&gt;
            </code>
            </pre>
            <ok-text-input
              dir="rtl"
              :label="`${$t('help.section')} (Arabic)`"
              is-required
            >
              <ok-editor
                :value="form.ar_text"
                :height="320"
                type="ckeditor4"
                @input="form.ar_text=$event"
              />
            </ok-text-input>
          </b-tab>
          <b-tab
            title="English"
          >
            <ok-text-input
              v-model="form.en_title"
              dir="ltr"
              :label="`${$t('help.section-title')} (English)`"
            />
            <ok-text-input
              :label="`${$t('help.section')} (English)`"
            >
              <ok-editor
                :value="form.en_text"
                :height="320"
                type="ckeditor4"
                @input="form.en_text=$event"
              />
            </ok-text-input>
          </b-tab>
        </b-tabs>
        <document-input-dropzone
          ref="dropzone"
          v-model="form.video"
          :max-files="1"
          accepted-files="image/jpg, image/png, image/jpeg, video/*"
          @remove="form.remove_video=$event"
        />
      </validation-observer>
    </div>
    <template #actions>
      <div class="d-flex justify-content-end align-items-center">

        <b-button
          variant="outline-dark"
          class="mr-50"
          @click="reset(), $emit('input',false)"
        >
          {{ $t('forms.cancel') }}
        </b-button>
        <b-button
          variant="dark"
          :disabled="loading"
          @click="submit"
        >
          {{ $t('forms.submit') }}
        </b-button>
      </div>
    </template>
  </ok-modal>
</template>

<script>
import { BButton, BTab, BTabs } from 'bootstrap-vue'
import cloneDeep from 'lodash/cloneDeep'
import isString from 'lodash/isString'
import { ValidationObserver } from 'vee-validate'
import OkModal from '@/@bya3/components/modals/OkModal'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'
import DocumentInputDropzone from '@/@bya3/components/inputs/file-inputs/DocumentInputDropzone'
import FormsService from '@/services/forms-service'
import HelpAPI from '@/api/admin/help-api'
import ErrorHandler from '@/utils/error-handler'
import ErrorAlert from '@/@bya3/components/alerts/ErrorAlert'
import GuideCategorySelect from '@/views/admin/help/components/GuideCategorySelect'

export default {
  name: 'ProcessGuideSection',
  components: {
    GuideCategorySelect,
    ErrorAlert,
    ValidationObserver,
    DocumentInputDropzone,
    OkEditor: () => import(/* webpackChunkName: "ok-editor" */ '@/@bya3/components/inputs/ok-editor/OkEditor'),
    OkTextInput,
    OkModal,
    BButton,
    BTab,
    BTabs,
  },
  props: {
    section: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      error: null,
      form: {},
    }
  },
  computed: {
    editMode() {
      return this.section?.id ?? false
    },
  },
  watch: {
    section() {
      this.setSection()
    },
  },
  mounted() {
    this.setSection()
  },
  methods: {
    setSection() {
      this.reset()
      if (this.editMode) {
        const imageUrl = this.question?.video ?? ''
        if (typeof imageUrl === 'string' && this.isValidURL(imageUrl)) {
          setTimeout(() => { this.addFileManually(imageUrl) }, 200)
        }
        this.form = { ...this.section }
        this.form.video = [this.section.video]
      }
    },
    isValidURL(string) {
      const res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g)
      return (res !== null)
    },
    reset() {
      this.form = {
        en_title: '',
        ar_title: '',
        en_text: '',
        category_id: null,
        ar_text: '',
        video: [],
        order: 0,
      }
    },
    async submit() {
      this.error = null
      const valid = await (this.$refs.form.validateWithInfo())
      if (!valid.isValid) {
        this.error = { title: this.$tc('errors.field-invalid', 2), message: ErrorHandler.getValidationErrors(valid.errors) }
      } else {
        this.loading = true
        const form = cloneDeep(this.form)
        if (this.editMode) {
          form.id = this.section.id
        }

        if (isString(form.video)) {
          delete form.video
        } else if (form?.video?.length) {
          form.video = form?.video[0] ?? ''
        }
        const formData = FormsService.convertObjectToFormData(form)
        await HelpAPI.editSection(formData)
          .then(response => {
            this.$notifySuccess(response.message)
            this.reset()
            this.$emit('input', false)
            this.$emit('processed', false)
          })
          .catch(err => {
            this.error = err
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    async addFileManually(imageUrl) {
      const imageName = imageUrl.substring(imageUrl.lastIndexOf('/') + 1)
      this.$refs.dropzone.manuallyAddFile(imageUrl, imageName, 0)
    },
  },
}
</script>
