<template>
  <ok-text-input
    v-bind="okTextProps"
    is-required
  >
    <b-form-select
      :value="value"
      :options="options"
      :disabled="disabled"
      @input="setPackage($event)"
    />
  </ok-text-input>
</template>

<script>
import { BFormSelect } from 'bootstrap-vue'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'
import okTextInputProps from '@/@bya3/components/inputs/utils/ok-text-input-props'
import LocalizationService from '@/services/localization-service'
import AddonCycleEnum from '@/models/subscription/SubscriptionTypesEnum'

export default {
  name: 'PackageSelect',
  components: { OkTextInput, BFormSelect },
  props: {
    ...okTextInputProps,
    withFree: {
      type: Boolean,
      default: false,
    },
    withTrial: {
      type: Boolean,
      default: false,
    },
    packagesList: {
      type: Array,
      default: () => [],
    },
    formQuotation: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    okTextProps() {
      return {
        ...this.$props,
        label: this.$t('subscription.package-name'),
        isRequired: true,
      }
    },
    packages() {
      return this.packagesList.length ? this.packagesList : this.$store.getters['subscription/packages']
    },
    options() {
      return this.packages
        .filter(_package => (this.withFree || _package.type !== AddonCycleEnum.Free) && (this.withTrial || _package.type !== AddonCycleEnum.Trial))
        .map(_package => ({ value: _package.id, text: LocalizationService.getTranslateArEn(_package, 'name') }))
        .concat([{ value: 'custom', text: this.$t('subscription.custom') }])
    },
  },
  mounted() {
    if (this.packages[0]?.id) this.setPackage(this.packages[0].id)
  },
  methods: {
    setPackage(packageId) {
      let packageObject = this.packages.find(p => p.id === packageId)
      let id = packageId
      if (this.formQuotation) {
        id = packageId ?? 'custom'
        packageObject = (!packageObject?.id) ? { id: 'custom' } : packageObject
      }
      this.$emit('input', id)
      this.$emit('package', packageObject)
    },
  },
}
</script>
