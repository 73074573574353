<template>
  <div class="subscription-form">
    <portal to="subscription-form-tab-actions">
      <div class="d-flex">
        <b-button
          v-if="subscription"
          class="action-button mr-1"
          size="lg"
          variant="outline-dark"
          @click="addonModalVisible = true"
        >
          {{ $t('accounts.add-addon') }}
        </b-button>
        <b-button
          v-if="subscription"
          class="action-button mr-1"
          size="lg"
          variant="outline-dark"
          @click="updateModal = true"
        >
          {{ $t('forms.edit') }}
        </b-button>
        <b-button
          class="action-button"
          variant="dark"
          size="lg"
          @click="packageModal = true"
        >
          {{ $t('accounts.change-package') }}
        </b-button>
      </div>
    </portal>
    <template>
      <div class="package-info">
        <b-row
          v-for="(record, idx) in records"
          :key="idx"
        >
          <b-col
            cols="6"
            class="font-weight-bold"
          >
            {{ record.text }}:
          </b-col>
          <b-col
            cols="6"
            class="font-weight-semi-bold"
          >
            <div :class="record.class || ''">
              {{ record.value }}
            </div>
          </b-col>
        </b-row>
      </div>

      <div class="addons-list mt-2">
        <div class="d-flex justify-content-between align-items-center">
          <div class="text-large font-weight-bold mb-1">
            {{ $t('subscription.purchased-addons') }}
          </div>
        </div>
        <b-row>
          <template v-for="(addon, idx) in addons">
            <b-col
              :key="idx"
              md="4"
            >
              <purchased-addon-card
                :addon="addon"
                :user-id="userId"
                @cancelled="handleCancelAddon(idx)"
              />
            </b-col>
          </template>
        </b-row>

        <div
          v-if="!addons.length"
          class="text-center"
        >
          {{ $t('accounts.no-addons') }}
        </div>
      </div>

      <add-addon-modal
        v-model="addonModalVisible"
        :subscription="subscription"
        :user-id="userId"
        @submitted="reload"
      />

      <change-package-modal
        v-model="packageModal"
        :subscription="subscription"
        :user-id="userId"
        @submitted="reload"
      />

      <change-package-modal
        v-model="renewModal"
        :subscription="subscription"
        :user-id="userId"
        renew-mode
        @submitted="reload"
      />
      <update-custom-package-modal
        v-model="updateModal"
        :subscription="subscription"
        :user-id="userId"
        renew-mode
        @submitted="reload"
      />
    </template>
  </div>
</template>

<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'
import LocalizationService from '@/services/localization-service'
import currency from 'currency.js'
import AddAddonModal from './AddAddonModal'
import ChangePackageModal from './ChangePackageModal'
import UpdateCustomPackageModal from './UpdatePackageModal'

export default {
  components: {
    UpdateCustomPackageModal,
    ChangePackageModal,
    AddAddonModal,
    BRow,
    BCol,
    BButton,
  },
  inject: ['accountDetails', 'reloadUser'],
  data() {
    return {
      addonModalVisible: false,
      renewModal: false,
      updateModal: false,
      packageModal: false,
    }
  },
  computed: {
    subscription: {
      get() { return this.accountDetails.subscription },
      set(val) { this.accountDetails.subscription = val },
    },
    addons() {
      return this.subscription?.addons ?? []
    },
    userId() {
      return this.$route.params.id || ''
    },
    records() {
      const {
        price, type, cycle, expires_at: expiresAt, plan,
      } = this.subscription ?? {}

      const expiresIn = this.$moment(expiresAt).diff(this.$moment().startOf('day'), 'day')
      const expired = expiresIn < 0
      const expiresSoon = expiresIn <= 3 && !expired
      const is_monthly = cycle === 0
      // eslint-disable-next-line no-nested-ternary
      const status = expired ? this.$t('subscription.expired') : (expiresSoon ? this.$t('subscription.expires-soon') : this.$t('subscription.active'))
      // eslint-disable-next-line no-nested-ternary
      const statusVariant = expired ? 'danger' : (expiresSoon ? 'warning' : 'success')
      const statusClass = `badge badge-light-${statusVariant}`
      const is_custom = type === 'custom'
      return [
        { text: this.$t('general.status'), value: status, class: statusClass },
        { text: this.$t('subscription.package-name'), value: plan ? (LocalizationService.getTranslateArEn(plan) || plan.slug) : '-' },
        { text: this.$t('general.price'), value: price ? this.currencyFormat(price) : '-' },
        // eslint-disable-next-line no-nested-ternary
        { text: this.$t('subscription.billing-cycle'), value: (['trial', 'free'].includes(type)) ? '-' : (is_custom ? this.$t('subscription.custom') : (is_monthly ? this.$t('subscription.billed-monthly') : this.$t('subscription.billed-annually'))) },
        { text: this.$t('subscription.next-invoice-date'), value: type === 'free' ? '-' : expiresAt },
      ]
    },
  },
  methods: {
    reload() {
      this.reloadUser()
    },
    handleCancelAddon(addonIdx) {
      this.subscription.addons.splice(addonIdx, 1)
    },
    currencyFormat(value) {
      return currency(value, { precision: 2 }).format()
    },
  },
}
</script>

<style lang="scss">
.subscription-form {
  .package-info {
    max-width: 500px;

    & > .row {
      padding: 7px 0;
    }
  }
}
</style>
