<template>
  <div class="admin-payment-status">
    <b-row>
      <b-col md="6">
        <ok-text-input
          #default="{ errors }"
          is-required
          :label="$t('orders.payment-status')"
        >
          <b-form-select
            :value="value"
            :options="options"
            :state="errors.length > 0 ? false:null"
            @input="$emit('input', $event)"
          />
        </ok-text-input>
      </b-col>
      <slot />
      <b-col
        v-if="value > 0 && value <3"
        md="12"
      >
        <ok-text-input
          #default="{ errors }"
          :is-required="!!value"
          :label="$t('subscription.receipt')"
        >
          <b-form-file
            id="file-wiretransfer"
            v-model="image"
            size="lg"
            :state="errors.length > 0 ? false:null"
            @input="$emit('update:image', $event)"
          />
        </ok-text-input>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormFile, BFormSelect,
} from 'bootstrap-vue'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'

export default {
  name: 'PaymentStatusInput',
  components: {
    OkTextInput,
    BFormFile,
    BFormSelect,
    BRow,
    BCol,
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      image: null,
    }
  },
  computed: {
    options() {
      return [
        { text: this.$t('subscription.paid-cash'), value: 1 },
        { text: this.$t('subscription.paid-wiretransfer'), value: 2 },
        { text: this.$t('subscription.unpaid'), value: 0 },
        { text: this.$t('subscription.gift'), value: 3 },
      ]
    },
  },
}
</script>

<style lang="scss">
.admin-payment-status {

}
</style>
