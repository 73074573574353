import SetupWizardConfigType from '@/@bya3/components/wizards/setup-wizard/SetupWizardConfigType'
import InfoForm from '../components/InfoForm.vue'
import SubscriptionForm from '../components/SubscriptionForm.vue'

const AccountFormConfig: SetupWizardConfigType = {
  tabs: [
    {
      title: 'accounts.basic-info',
      icon: 'feather icon-user',
      description: '',
      canSkip: true,
      props: {},
      id: 'basic-form',
      skipButtonTitle: 'forms.cancel',
      component: InfoForm,
    },
    {
      title: 'accounts.subscription',
      icon: 'feather icon-dollar-sign',
      description: '',
      hide: false,
      canSkip: true,
      hidePrev: true,
      customActions: true,
      props: {},
      id: 'subscription-form',
      component: SubscriptionForm,
    },
  ],
}

export default AccountFormConfig
