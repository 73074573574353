import OkTableConfigType from '@/@bya3/components/tables/ok-table/OkTableConfigType'
import { API_URL } from '@/constants/config'
import store from '@/store'
import LocalizationService from '@/services/localization-service'

const InvoicesTableConfig: OkTableConfigType = {
  mode: 'remote',
  url: `${API_URL}/invoices`,
  rowsPath: 'invoices',
  rowClickable: true,
  attachPageId: false,
  columns: [
    {
      label: 'fields.name',
      field: 'entity',
      formatFn: (val: any) => val.name,
    },
    {
      label: 'fields.type',
      field: 'type',
    },
    {
      label: 'invoice.cost',
      field: 'cost',
      formatFn: (val: any) => `${val} ${store.getters['auth/currency']}`,
    },
    {
      label: 'invoice.discount',
      field: 'discount',
      formatFn: (val: any) => `${val} ${store.getters['auth/currency']}`,
    },
    {
      label: 'invoice.paid',
      field: 'paid',
    },
    {
      label: 'invoice.paid-at',
      field: 'paid_at',
    },
    {
      label: 'fields.created-at',
      field: 'created_at',
    },
  ],
  paginationOptions: {
    enabled: true,
  },
  sortOptions: {
    enabled: false,
  },
  actions: [
  ],
  filters: [
    {
      label: 'general.id',
      query: 'id',
      colLength: 3,
    },
    {
      label: 'fields.email',
      query: 'email',
      colLength: 3,
    },
    {
      label: 'business.name',
      query: 'name',
      colLength: 3,
    },
    {
      label: 'invoice.plan',
      query: 'package',
      colLength: 3,
      type: 'select',
      multiple: true,
      options: () => store.getters['pricing/allPackages'].map((plan: any) => ({ ...plan, name: LocalizationService.getTranslate(plan, 'title') })).concat([{ name: 'Custom', id: 0 }]),
      optionsLabel: { keyLabel: 'name', valueLabel: 'id', translate: false },
    },
    {
      label: 'subscription.add-ons',
      query: 'addons',
      colLength: 3,
      type: 'select',
      options: store.getters['pricing/addons'],
      optionsLabel: { keyLabel: 'slug', valueLabel: 'id', translate: false },
    },
    {
      label: 'subscription.billing-cycle',
      query: 'monthly',
      colLength: 3,
      type: 'select',
      multiple: true,
      options: [{ text: 'payment.monthly', value: 1 }, { text: 'payment.annually', value: 0 }, { text: 'payment.custom', value: 2 }],
      optionsLabel: { keyLabel: 'text', valueLabel: 'value', translate: true },
    },
    {
      label: 'orders.payment-status',
      query: 'status',
      colLength: 3,
      type: 'select',
      multiple: true,
      options: [{ text: 'general.paid', value: 1 }, { text: 'general.unpaid', value: 0 }],
      optionsLabel: { keyLabel: 'text', valueLabel: 'value', translate: true },
    },
    {
      label: 'subscription.amount',
      query: 'amount',
      colLength: 3,
      type: 'range',
      range_type: 'number',
    },
    {
      label: 'subscription.expiry-date',
      query: 'expires',
      colLength: 3,
      type: 'range',
      range_type: 'date',
    },
    {
      label: 'subscription.paid-at',
      query: 'paid_at',
      colLength: 3,
      type: 'range',
      range_type: 'date',
    },
    {
      label: 'fields.created-at',
      query: 'created',
      colLength: 3,
      type: 'range',
      range_type: 'date',
    },
    {
      label: 'general.note',
      query: 'note',
      colLength: 3,
    },
    {
      label: 'accounts.has-note',
      query: 'has_note',
      colLength: 3,
      type: 'select',
      multiple: false,
      options: [{ name: 'general.no', value: 0 }, { name: 'general.yes', value: 1 }],
      optionsLabel: { keyLabel: 'name', valueLabel: 'value', translate: true },
    },
  ],

}

export default InvoicesTableConfig
