<template>
  <div class="plans-view">

    <fetching-data-spinner
      :error="error"
      :loading="loading"
      :loading-title="$t('subscription.loading-plans')"
      enable-retry
      @retry="loadSettings"
    />

    <div v-if="!loading && !error">
      <div>
        <div class="our-plans">
          {{ $t('subscription.our-plans') }}
        </div>
        <pricing-cards
          hide-teaser
          super-admin
          :monthly="cycle === 'monthly'"
          @select="handleSelectPackage"
        />
      </div>
    </div>
    <edit-plan-modal
      v-model="showPlanModal"
      :plan="selectedPlan"
      @updated="loadSettings"
    />
  </div>
</template>

<script>
import FetchingDataSpinner from '@/@bya3/components/utils/FetchingDataSpinner'
import EditPlanModal from './components/EditPlanModal'

export default {
  components: {
    EditPlanModal,
    FetchingDataSpinner,
  },
  data() {
    return {
      loading: false,
      error: null,
      cycle: 'yearly',
      showPlanModal: false,
      selectedPlan: null,
      selectedFeature: null,
      showFeatureModal: false,
    }
  },
  mounted() {
    this.loadSettings()
  },
  methods: {
    handleSelectPackage(plan) {
      this.selectedPlan = plan
      this.showPlanModal = true
    },
    async loadSettings() {
      this.error = null
      this.loading = true
      await this.$store.dispatch('pricing/FETCH_FEATURES', true)
      await this.$store.dispatch('pricing/FETCH_PRICING', true)
        .catch(error => { this.error = error })
        .finally(() => { this.loading = false })
    },
    handleSelectFeature(feature) {
      this.selectedFeature = feature
      this.showFeatureModal = true
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/utils/responsive.scss";

.plans-view {
  padding-top: 102px;
  padding-bottom: 50px;
  background: var(--white);

  &.show-purchase-view {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  @include respond-to-max('medium') {
    padding-top: 20px;
  }

  .our-plans {
    font-weight: 800;
    font-size: 28px;
    margin-bottom: 20px;
    text-align: center;
    color: var(--purple);
  }

  .zero-commission-note {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 18px;
    text-align: center;
    color: var(--description-color);
  }

}
</style>
