import $http from '@/libs/axios'

class HelpAPI {
  public static async edit(data: any) {
    return (await $http.post('admin/guide/categories', data)).data
  }

  public static async editSection(data: any) {
    return (await $http.post('admin/guide/sections', data)).data
  }

  public static async delete(id: any) {
    return (await $http.delete(`admin/guide/categories/${id}`)).data
  }

  public static async deleteSection(id: any) {
    return (await $http.delete(`admin/guide/sections/${id}`)).data
  }

  public static async show(id: any) {
    return (await $http.get(`admin/help/questions/${id}`)).data
  }

  public static async categoriesList(onlyChildren = false, onlyParent = false) {
    let url = 'admin/guide/categories'
    let query = '?'
    if (onlyChildren) query += 'only_child=true'
    else if (onlyParent) query += 'only_parent=true'
    if (query !== '?') url += query
    return (await $http.get(url)).data
  }

  public static async getQuestion(slug: string) {
    return (await $http.get(`help/questions/${slug}`)).data
  }
}

export default HelpAPI
