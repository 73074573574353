<template>
  <div>
    <VuePhoneNumberInput
      ref="phoneNumber"
      v-model="phoneNumber"
      :dark="isDark"
      color="#ecae21"
      :size="size"
      error-color="#ea5455"
      dir="ltr"
      :no-use-browser-locale="!defaultCountryCodeComputed"
      :no-example="noExample"
      :valid-color="phoneInputValidColor"
      :fetch-country="fetchCountry && !value"
      :error="!valid && !!phoneNumber"
      :ignored-countries="ignoredCountries"
      :preferred-countries="preferredCountries"
      :default-country-code="defaultCountryCodeComputed"
      :translations="translations"
      :only-countries="onlyCountries"
      :disabled="disabled"
      @update="handleUpdate"
    />
    <small
      v-if="!valid && showInvalidFeedback"
      class="text-danger"
      v-text="$t('validations.invalid-phone-number')"
    />
  </div>
</template>

<script>
import(/* webpackPrefetch: 0 */ /* webpackPreload: 0 */ /* webpackChunkName: "phone-input" */ 'vue-phone-number-input/dist/vue-phone-number-input.css')

export default {
  name: 'OkPhoneNumberInput',
  components: { VuePhoneNumberInput: () => import(/* webpackPrefetch: 0 */ /* webpackPreload: 0 */ /* webpackChunkName: "phone-input" */ 'vue-phone-number-input') },
  props: {
    value: {
      type: [String],
      required: false,
      default: () => '',
    },
    fetchCountry: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      default: true,
    },
    noExample: {
      type: Boolean,
      default: false,
    },
    defaultCountryCode: {
      type: [String],
      default: 'SY',
    },
    size: {
      type: [String],
      default: null,
    },
    onlyCountries: {
      type: Array,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valid: false,
      showInvalidFeedback: false,
      phoneNumber: '',
      countryCode: '',
      defaultCountryCodeComputed: null,
      translations: {
        countrySelectorLabel: this.$t('fields.country'),
        phoneNumberLabel: this.$t('fields.phone-number'),
      },
    }
  },
  computed: {
    phoneInputValidColor() {
      return this.isDark ? '#676d7d' : '#d8d6de'
    },
    preferredCountries() {
      return ['SY', 'LB', 'IQ', 'JO', 'EG', 'KW', 'AE', 'SA', 'QA', 'OM', 'BH']
    },
    ignoredCountries() {
      return ['IL']
    },
  },
  watch: {
    value(number) {
      if (number === '') this.phoneNumber = ''
      this.setPhoneNumber(number)
    },
    defaultCountryCode() {
      this.setDefaultCountryCode()
    },
    valid(valid) {
      this.$emit('update:valid', valid)
    },
    '$i18n.locale': function () {
      this.translations = {
        countrySelectorLabel: this.$t('fields.country'),
        phoneNumberLabel: this.$t('fields.phone'),
      }
    },
  },
  mounted() {
    this.setPhoneNumber(this.value)
    this.setDefaultCountryCode()
  },
  methods: {
    handleUpdate(payload) {
      this.countryCode = payload.countryCode ?? this.countryCode
      this.showInvalidFeedback = false
      this.valid = payload.isValid
      if (payload.e164) {
        this.$emit('input', payload.e164)
      }
      this.$emit('update:country-calling-code', payload.countryCallingCode)
    },
    setPhoneNumber(number) {
      if (this.phoneNumber) {
        return
      }
      this.valid = true
      this.phoneNumber = number
    },
    validate() {
      this.showInvalidFeedback = !this.valid
      return this.valid
    },
    getCountryDetails(code) {
      const country = this.$refs.phoneNumber?.codesCountries?.find(c => c.iso2 === (code || this.countryCode))
      return {
        country_iso: country?.iso2,
        country: country?.name,
      }
    },
    getCountryFromDialCode(dialCode) {
      const country = this.$refs.phoneNumber?.codesCountries?.find(c => c.dialCode === dialCode)
      return {
        country_iso: country?.iso2,
        country: country?.name,
      }
    },
    setDefaultCountryCode() {
      setTimeout(() => {
        if (this.value) {
          this.defaultCountryCodeComputed = null
          return
        }

        const defaultCountry = this.defaultCountryCode || this.$store.getters['branches/currentBranch']?.country_iso || null

        if (defaultCountry) {
          this.defaultCountryCodeComputed = defaultCountry
        } else {
          this.defaultCountryCodeComputed = null
        }
      }, 2000)
    },
  },
}
</script>

<style lang="scss">

.input-tel:not(.has-error):not(.is-valid).input-phone-number,
.country-selector.input-country-selector {
  .country-selector__list__item.selected {
    background-color: var(--okmenu-color) !important;
  }

  input {
    background: transparent !important;
  }

  &.is-dark input {
    border-color: var(--theme-dark-input-border-color) !important;
  }

  &:hover {
    border-color: var(--okmenu-color) !important;
  }

  &.is-focused {
    input {
      caret-color: var(--okmenu-color) !important;
      box-shadow: unset!important;
      border-color: var(--okmenu-color) !important;
    }

    label {
      color: var(--okmenu-color) !important;
    }
  }
}

.country-selector.lg .country-selector__input, .input-tel.lg .input-tel__input{
  height: 3.2857rem!important;
  min-height: 3.2857rem!important;
  font-size: 1.143rem!important;
  line-height: 1.25!important;
}

.input-tel.lg .input-tel__label {
  font-size: 11px!important;
}

.input-tel__input {
  background-color: transparent!important;
  .dark-layout & {
    border-color: var(--theme-dark-input-border-color)!important;
  }
}

</style>
