<template>
  <b-card
    class="position-relative mb-1"
    body-class="statistics-count-card"
  >
    <div class="d-flex">
      <div
        class="mr-1"
      >
        <b-avatar
          size="57"
          :variant="variant"
        >
          <feather-icon
            :icon="feather"
            size="26"
          />
        </b-avatar>
      </div>
      <div class="w-100">
        <div class="title one-line d-flex">
          {{ title }}
        </div>
        <div class="title d-flex justify-content-between w-100">
          <span>{{ value }}</span>
          <span
            v-if="secondValue !== null"
            class="text-danger"
          >({{ secondValue }})</span>
          <feather-icon
            v-if="titleIcon"
            :icon="titleIcon"
            size="20"
            class="ml-50 statistics-title-icon"
            :class="titleIcon"
          />
        </div>
      </div>
    </div>

    <ok-tooltip
      v-if="tooltip"
      :text="tooltip"
    />
  </b-card>
</template>

<script>
import { BCard, BAvatar } from 'bootstrap-vue'
import OkTooltip from '@/@bya3/components/utils/OkTooltip'

export default {
  name: 'StatisticsCountCard',
  components: {
    OkTooltip,
    BCard,
    BAvatar,
  },
  props: {
    titleIcon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'light-primary',
    },
    value: {
      type: [Number, String],
      default: 0,
    },
    tooltip: {
      type: String,
      default: null,
    },
    feather: {
      type: String,
      default: null,
    },
    secondValue: {
      type: Number,
      default: null,
    },
  },
}
</script>

<style lang="scss">
.statistics-count-card {
  padding: 15px 22px;
  overflow: hidden;

  img {
    margin-right: 15px;
  }

  .title {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.39;
    color: #5e5873;
    margin-bottom: 3px;
  }

  .ok-tooltip-icon {
    color: var(--description-color);
    position: absolute;
    bottom: 13.4px;
    right: 13.4px;
  }
}
</style>
