<template>
  <ok-modal
    :value="value"
    size="lg"
    dialog-class="tables-modal"
    :title="$t('forms.'+(isEdit?'edit':'add'))"
    @change="$emit('input',false)"
  >
    <type-form
      ref="types"
      :business-type="type"
      @updated="$emit('updated')"
      @submitting="loading = $event"
    />
    <template #actions>
      <div class="d-flex justify-content-end align-items-center">
        <b-button
          variant="outline-dark"
          :disabled="loading"
          @click="submit"
        >
          <b-spinner
            v-if="loading"
            small
            class="ml-1"
          />
          {{ $t('forms.save') }}
        </b-button>
        <b-button
          variant="dark"
          class="ml-1"
          @click="$emit('input',false)"
        >
          {{ $t('forms.cancel') }}
        </b-button>
      </div>
    </template>
  </ok-modal>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import OkModal from '@/@bya3/components/modals/OkModal'
import TypeForm from '@/views/admin/business-types/components/TypeForm'

export default {
  name: 'ProcessTypeModal',
  components: {
    TypeForm,
    OkModal,
    BButton,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    isEdit() {
      return !!this.type?.id
    },
  },
  methods: {
    async submit() {
      await this.$refs.types.save()
    },
  },
}
</script>
