import OkTableConfigType from '@/@bya3/components/tables/ok-table/OkTableConfigType'

const PackagesTabletConfig: OkTableConfigType = {
  mode: 'local',
  actionFirst: true,
  columns: [
    {
      label: 'page.package-name',
      field: 'name',
    },
    {
      label: 'statistics.pages-count',
      field: 'total',
    },
    {
      label: 'statistics.active-pages',
      field: 'active',
    },
    {
      label: 'statistics.expired-pages',
      field: 'expired',
    },
  ],
  paginationOptions: {
    enabled: true,
  },
  sortOptions: {
    enabled: false,
  },
  actions: [
    {
      label: 'statistics.view-pages',
      eventName: 'view',
      icon: 'EyeIcon',
    },
  ],
  filters: [],
}

export default PackagesTabletConfig
