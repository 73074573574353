import { saveAs } from 'file-saver'
import $http from '@/libs/axios'
import FormsService from '@/services/forms-service'

export default class AccountsAPI {
  static async delete(id: number) {
    return (await $http.delete(`admin/users/${id}`)).data
  }

  public static async submit(body: any) {
    const formData = FormsService.convertObjectToFormData(body)
    const config = { headers: FormsService.MULTIPART_HEADER }
    return (await $http.post('admin/users', formData, config)).data
  }

  static async updateUser(id: number, data: any) {
    return (await $http.post(`admin/users/update/${id}`, data)).data
  }

  public static async impersonate(userId: number) {
    return (await $http.get(`admin/users/fake-login/${userId}`)).data
  }

  static async exportAccounts(filters: any) {
    const url = `admin/users/accounts/export?${FormsService.prepareQueryString(filters, true)}`
    const file = (await $http.get(url, { responseType: 'blob' })).data
    saveAs(file, 'accounts.xlsx')
  }

  public static async updateAccountPackage(data: any) {
    return (await $http.post('admin/subscription/subscribe', data, { headers: { 'Content-Type': 'multipart/form-data' } })).data
  }

  public static async updateAccountFeatures(data: any) {
    return (await $http.post('admin/subscription/features', data, { headers: { 'Content-Type': 'multipart/form-data' } })).data
  }

  public static async renewAccountSubscription(data: any) {
    return (await $http.post('admin/subscription/renew', data, { headers: { 'Content-Type': 'multipart/form-data' } })).data
  }

  public static async getProfile(id: number) {
    return (await $http.get(`admin/users/${id}`)).data
  }
}
