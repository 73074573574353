<template>
  <b-input-group
    class="password-input input-group-merge"
    :class="invalid ? 'is-invalid':null"
  >
    <b-form-input
      id="password-input"
      autocomplete="new-password"
      :value="value"
      class="form-control-merge"
      :type="passwordFieldType"
      :state="invalid ? false:null"
      name="password-input"
      :size="size"
      dir="ltr"
      placeholder="············"
      @input="$emit('input', $event)"
    />
    <b-input-group-append is-text>
      <feather-icon
        :icon="passwordToggleIcon"
        class="cursor-pointer"
        @click="togglePasswordVisibility"
      />
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import { BInputGroup, BFormInput, BInputGroupAppend } from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  name: 'PasswordInput',
  components: {
    BInputGroup, BFormInput, BInputGroupAppend,
  },
  mixins: [togglePasswordVisibility],
  props: {
    value: {
      type: String,
      default: '',
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
}
</script>

<style lang="scss">
.password-input {
  input {
    text-align: left;
  }
}
</style>
