<template>
  <div class="mt-2">
    <error-alert
      v-if="error"
      :error="error"
    />
    <validation-observer ref="form">
      <div class="h4 font-weight-bold  mb-2">
        {{ $t('accounts.personal-info') }}
      </div>
      <b-row>
        <b-col
          class="d-flex justify-content-between flex-column"
          md="6"
          sm="12"
        >
          <ok-text-input
            v-model.trim="form.name"
            :label="$t('fields.name')"
            is-required
          />
        </b-col>
        <b-col
          class="d-flex justify-content-between flex-column"
          md="6"
          sm="12"
        >
          <ok-text-input
            v-model.trim="form.email"
            :label="$t('fields.email')"
            is-required
            rules="required|email"
          />
        </b-col>
        <b-col
          md="6"
          sm="12"
        >
          <ok-text-input
            :label="$t('fields.phone-number')"
            is-required
          >
            <ok-phone-number-input v-model="form.phone" />
          </ok-text-input>
        </b-col>
        <b-col
          v-if="!edit"
          class="mb-1"
          md="6"
        >
          <ok-select
            v-model="form.city"
            :label="$t('fields.city')"
            :options="Cities"
            is-required
            size="md"
          />
        </b-col>
        <b-col
          md="6"
          sm="12"
        >
          <ok-text-input
            v-model.trim="form.password"
            :is-required="createMode"
            :label="$t('fields.password')"
            rules="max:50|min:6"
          >
            <template #default="{ errors }">
              <password-input
                v-model="form.password"
                :invalid="errors.length > 0"
              />
            </template>
          </ok-text-input>
        </b-col>
        <b-col
          class="d-flex justify-content-between flex-column"
          md="6"
          sm="12"
        >
          <ok-text-input
            v-model.trim="form.domain"
            :label="$t('store.domain')"
            is-required
          />
        </b-col>
        <b-col
          class="mb-1"
          md="6"
        >
          <ok-text-input
            :label="$t('business.locales')"
            is-required
            size="md"
          >
            <localization-language-select
              v-model="form.locales"
              all
              multiple
              with-ui
            />
          </ok-text-input>
        </b-col>
      </b-row>

      <div class="h4 font-weight-bold  my-2">
        {{ $t('accounts.business-info') }}
      </div>
      <b-row>
        <b-col
          class="d-flex flex-column"
          md="6"
          sm="12"
        >
          <ok-text-input
            v-model.trim="form.business_name"
            :label="$t('business.name')"
            is-required
          />

          <ok-text-input
            v-model.trim="form.business_type"
            :label="$t('accounts.business-type')"
            is-required
          >
            <template #default="{ errors }">
              <business-types-select
                v-model="form.business_type_id"
                :state="errors.length > 0 ? false:null"
                select-restaurant
              />
            </template>
          </ok-text-input>

          <div class="d-flex justify-content-start mt-1 mb-3">
            <b-form-checkbox
              v-model="form.remove_power_by"
              :unchecked-value="0"
              :value="1"
            >
              {{ $t('accounts.powered-by') }}
            </b-form-checkbox>
          </div>
        </b-col>

        <b-col
          md="6"
          sm="12"
        >
          <b-form-group :label="$t('business.logo')">
            <logo-file-input
              ref="logo-input"
              v-model="form.logo"
              with-extract-colors
              @colors="handleUpdateColors"
              @removed="form.remove_logo = true"
            />
          </b-form-group>
        </b-col>
      </b-row>

    </validation-observer>
  </div>
</template>

<script>
import {
  BCol, BFormCheckbox, BFormGroup, BRow,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import '@validations'
import { mapGetters } from 'vuex'
import LogoFileInput from '@/@bya3/components/inputs/LogoFileInput.vue'
import PasswordInput from '@/@bya3/components/inputs/PasswordInput'
import ErrorAlert from '@/@bya3/components/alerts/ErrorAlert'
import AccountsAPI from '@/api/admin/users'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'
import BusinessTypesSelect from '@/@bya3/components/inputs/BusinessTypesSelect.vue'
import LocalizationLanguageSelect from '@/views/store/inputs/StoreLanguageSelect'
import OkPhoneNumberInput from '@/@bya3/components/inputs/OkPhoneNumberInput'
import OkSelect from '@/@bya3/components/inputs/OkSelect'
import Cities from '@/constants/cities'

export default {
  name: 'InfoForm',
  components: {
    OkSelect,
    OkPhoneNumberInput,
    LocalizationLanguageSelect,
    OkTextInput,
    ErrorAlert,
    PasswordInput,
    LogoFileInput,
    BusinessTypesSelect,
    BRow,
    BCol,
    BFormGroup,
    ValidationObserver,
    BFormCheckbox,
  },
  inject: ['accountDetails', 'setUser'],
  data() {
    return {
      form: {
        business_name: '',
        business_type_id: null,
        remove_logo: null,
        logo: null,
        domain: '',
        locales: ['ar'],
        email: '',
        password: '',
        name: '',
        phone: '',
        remove_power_by: false,
      },
      error: null,
      Cities,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    account() {
      return this.accountDetails.account ?? {}
    },
    edit() {
      return this.$route?.params?.id
    },
    createMode() {
      return !this.$route?.params?.id
    },
  },
  watch: {
    account() {
      this.init()
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      this.error = null

      if (this.edit) {
        this.form = {
          remove_logo: 0,
          business_name: this.account?.business_name,
          business_type_id: this.account?.type_id ?? null,
          logo: this.account?.logo,
          name: this.account?.name,
          phone: this.account?.phone,
          locales: this.account?.locales ?? ['ar'],
          domain: this.account?.domain,
          email: this.account?.email,
          id: this.account?.id,
          remove_power_by: this.account?.remove_power_by,
        }
      }
    },
    async submit() {
      this.error = null
      const valid = await this.$refs.form.validate()
      if (!valid) {
        this.error = this.$tc('errors.field-invalid', 2)
        throw this.error
      }
      const form = { ...this.form }

      if (!form.logo || (typeof form.logo === 'string')) {
        delete form.logo
      }
      if (!form.remove_logo) {
        delete form.remove_logo
      }

      return AccountsAPI.submit(form)
        .then(response => {
          this.$notifySuccess(response.message)
          if (this.createMode) {
            this.$router.replace({ name: 'admin:accounts-add', params: { id: response?.user?.id } }).catch(() => {
            })
            this.setUser(response?.user)
          }
        })
        .catch(e => {
          this.error = e
        })
    },
    handleUpdateColors(colors) {
      if (!colors) {
        this.form.primary_color = null
        this.form.secondary_color = null
        return
      }

      const [primaryColor, secondaryColor] = colors
      this.form.primary_color = primaryColor
      this.form.secondary_color = secondaryColor
    },
  },
}
</script>
