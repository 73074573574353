<template>
  <ok-modal
    :value="value"
    size="lg"
    dialog-class="tables-modal"
    :title="$t('plans.edit-plan')"
    @change="$emit('input',false)"
  >
    <validation-observer ref="form">
      <div>
        <error-alert
          v-if="error"
          :error="error"
        />
        <b-tabs>
          <b-tab
            :title="$t('my-menu.general')"
          >
            <template v-if="locales[$i18n.locale]">
              <ok-text-input
                v-model="locales[$i18n.locale].title"
                is-required
                from-component
                :label="$t('fields.name')"
              />
              <ok-text-input
                v-model="locales[$i18n.locale].description"
                is-required
                from-component
                textarea
                :label="$t('fields.description')"
              />
            </template>
            <b-row>
              <b-col md="6">
                <price-input
                  v-model.trim="form.monthly_price"
                  allow-zero-number
                  :label="$t('subscription.monthly-price')"
                />
              </b-col>

              <b-col md="6">
                <price-input
                  v-model.trim="form.yearly_price"
                  allow-zero-number
                  :label="$t('subscription.yearly-price')"
                />
              </b-col>
            </b-row>
            <custom-features-limit
              v-model="form.features"
            />
          </b-tab>
          <b-tab
            :title="$t('general.different-languages')"
          >
            <div
              class="mt-2"
            >
              <b-form-group
                label-cols="3"
                :label="$t('my-menu.language')+':'"
              >
                <div id="language-select">
                  <localization-language-select
                    v-model="selectedLocale"
                    class="mb-1"
                    :branches="[]"
                  />
                </div>
              </b-form-group>

              <template v-if="locales[selectedLocale]">
                <ok-text-input
                  v-model="locales[selectedLocale].title"
                  label-cols="3"
                  label-size="lg"
                  from-component
                  :label="$t('general.translation')+':'"
                  :placeholder="$t('fields.name')"
                />
                <ok-text-input
                  v-model="locales[selectedLocale].description"
                  label-cols="3"
                  textarea
                  label-size="lg"
                  from-component
                  :label="$t('general.translation')+':'"
                  :placeholder="$t('fields.description')"
                />
              </template>
            </div>

          </b-tab>
        </b-tabs>
      </div>
    </validation-observer>
    <template #actions>
      <div class="d-flex justify-content-end align-items-center">
        <b-button
          variant="outline-dark"
          class="mr-1"
          @click="$emit('input',false)"
        >
          {{ $t('forms.cancel') }}
        </b-button>
        <b-button
          variant="dark"
          :disabled="loading"
          @click="submit"
        >
          {{ $t('forms.save') }}
          <b-spinner
            v-if="loading"
            small
            class="mr-1"
          />
        </b-button>

      </div>
    </template>
  </ok-modal>
</template>

<script>

import {
  BButton, BTab, BTabs, BRow, BCol, BSpinner, BFormGroup,
} from 'bootstrap-vue'
import cloneDeep from 'lodash/cloneDeep'
import { ValidationObserver } from 'vee-validate'
import OkModal from '@/@bya3/components/modals/OkModal'
import ErrorHandler from '@/utils/error-handler'
import ErrorAlert from '@/@bya3/components/alerts/ErrorAlert'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'
import PriceInput from '@/@bya3/components/inputs/PriceInput'

export default {
  name: 'EditPlanModal',
  components: {
    ErrorAlert,
    ValidationObserver,
    PriceInput,
    OkTextInput,
    BFormGroup,
    OkModal,
    BButton,
    BTab,
    BTabs,
    BRow,
    BCol,
    BSpinner,
  },
  props: {
    plan: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedLocale: this.$i18n.locale,
      locales: {},
      form: {
        features: {},
      },
      loading: false,
      error: null,
    }
  },
  watch: {
    selectedLocale(val) {
      if (!this.locales[val]?.title) this.locales[val] = { title: '', description: '' }
    },
    plan() {
      this.setData()
    },
  },
  mounted() {
    if (this.plan?.id) this.setData()
  },
  methods: {
    setData() {
      if (!this.plan) return

      (this.plan?.locales ?? []).forEach(locale => {
        this.locales[locale.locale] = { title: locale.title, description: locale.description }
      })
      if (!this.locales[this.$i18n.locale]) {
        this.locales[this.$i18n.locale] = { title: '', description: '' }
      }
      this.plan.features.forEach(feature => {
        this.form.features[feature.slug] = { limit: feature.limit }
      })
      this.form.monthly_price = this.plan?.monthly_price ?? 0
      this.form.yearly_price = this.plan?.yearly_price ?? 0
    },
    async submit() {
      this.error = null
      const valid = await (this.$refs.form.validateWithInfo())
      if (!valid.isValid) {
        this.error = { title: this.$tc('errors.field-invalid', 2), message: ErrorHandler.getValidationErrors(valid.errors) }
      } else {
        this.loading = true
        const form = cloneDeep(this.form)
        const locale = Object.keys(this.locales).map(localeKey => ({ locale: localeKey, ...this.locales[localeKey] }))
        form.locales = JSON.stringify(locale)
        const features = Object.keys(form.features).reduce((prev, featureSlug) => {
          const featureId = this.plan.features.find(feature => feature.slug === featureSlug)?.id
          if (!featureId) return { ...prev }
          return { ...prev, [featureId]: form.features[featureSlug] }
        }, {})
        form.features = JSON.stringify(features)
        form.id = this.plan.id
        /* await PlansApi.updatePackage(form).then(res => {
          this.$notifySuccess(res.message)
          this.$emit('updated', true)
          this.$emit('input', false)
        }).finally(() => { this.loading = false }) */
      }
    },
  },
}
</script>
