<template>
  <div>
    <b-card
      class="dashboard-card superadmin-items-card"
      body-class="p-0"
    >
      <div class="title">
        {{ $t('statistics.'+title) }}
      </div>

      <div
        v-if="!items.length"
        class="pb-1 description-text text-large text-center"
      >
        {{ $t('statistics.'+emptyTitle) }}
      </div>

      <div class="superadmin-table">
        <ok-table
          v-show="items.length"
          :config="config"
          :data="items"
          @view="openModal"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, VBTooltip } from 'bootstrap-vue'
import OkTable from '@/@bya3/components/tables/ok-table/OkTable'
import PackagesTabletConfig from './packages-tablet-config'

export default {
  name: 'PackagesTable',
  components: { OkTable, BCard },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: 'new-accounts',
    },
    emptyTitle: {
      type: String,
      default: 'no-data',
    },
  },
  data() {
    return {
      config: PackagesTabletConfig,
      selectedPackage: null,
      showModal: false,
    }
  },
  methods: {
    openModal(data) {
      this.showModal = true
      this.selectedPackage = data.row
    },
  },
}
</script>
