import $http from '@/libs/axios'
import FormsService from '@/services/forms-service'

class SubscriptionAPIs {
  static async subscribeTrial(packageSlug = 'trial_pro', userId?: null) {
    return (await $http.put(`/subscription/subscribe-trial/${packageSlug}/${userId || ''}`)).data
  }

  static async extendTrial() {
    return (await $http.put('/subscription/extend-trial')).data
  }

  static async getMySubscriptionDetails() {
    return (await $http.get('/subscription/mine')).data
  }

  static async purchasePackage(payload: any) {
    return (await $http.post('payment/package/url', payload, { headers: FormsService.MULTIPART_HEADER })).data
  }

  static async purchaseAddon(payload: any) {
    return (await $http.post('payment/feature/url', payload, { headers: FormsService.MULTIPART_HEADER })).data
  }

  static async subscribeFree() {
    return (await $http.put('/subscription/subscribe-free')).data
  }

  static async customRequest(message = '') {
    return (await $http.get(`/subscription/custom-request${message ? `?message=${message}` : ''}`)).data
  }

  static async renewSubscription(payload: any = {}) {
    return (await $http.post('/subscription/renew', payload)).data
  }

  static async customSubscription(payload: any = {}) {
    return (await $http.post('/packages/add-custom', payload)).data
  }

  static async updateCustomSubscription(packageId: number, payload: any = {}) {
    return (await $http.post(`/packages/update-custom/${packageId}`, payload)).data
  }

  static async qrSubscription(payload: any = {}) {
    return (await $http.post('/packages/assign-qr', payload)).data
  }

  static async upgrade(payload: any) {
    return (await $http.post('/subscription/upgrade', payload, { headers: FormsService.MULTIPART_HEADER })).data
  }

  static async cancelAddon(addonId: number, userId: number | null) {
    const response = (await $http.get(`/subscription/feature/cancel/${addonId}${userId ? `/${userId}` : ''}`)).data
    if (response.error) {
      throw new Error(response.message)
    }
    return response
  }

  static async subscribeToFreePlan() {
    const response = (await $http.put('/subscription/subscribe-free')).data
    if (response.error) throw new Error(response.message)
    return response
  }

  // super-admin
  public static async addAddons(data: any) {
    return (await $http.post('users/accounts/features', data, { headers: FormsService.MULTIPART_HEADER })).data
  }

  public static async changePackage(data: any) {
    return (await $http.post('admin/subscription/subscribe', data, { headers: FormsService.MULTIPART_HEADER })).data
  }

  static async addOnRequest(payload: any = {}) {
    return (await $http.put('/features/branded-domain/add', payload)).data
  }

  static async getUserBranchesAddons(userId: number) {
    return (await $http.get(`/subscription/addons/add-dependencies/${userId}`)).data
  }
}

export default SubscriptionAPIs
