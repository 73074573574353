<template>
  <ok-modal
    :value="value"
    size="lg"
    :title="$t('forms.update').toString()"
    title-class="large-modal-title"
    @change="$event ? null : close()"
  >
    <error-alert :error="error" />

    <validation-observer
      ref="form"
      #default="{invalid}"
      tag="div"
    >
      <b-row>
        <b-col md="12">
          <custom-features-limit
            v-model="customFeatures"
          />
        </b-col>
        <b-col md="12">
          <package-permissions v-model="form.permissions" />
        </b-col>
      </b-row>

      <div class="d-flex justify-content-end align-items-center mt-1">
        <b-button
          size="lg"
          variant="secondary"
          :disabled="invalid || loading"
          @click="submit"
        >
          <b-spinner
            v-if="loading"
            small
          />
          <template v-else>
            {{ $t('forms.submit') }}
          </template>
        </b-button>
      </div>
    </validation-observer>
  </ok-modal>
</template>

<script>
import {
  BRow, BCol, BButton, BSpinner,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'
import OkModal from '@/@bya3/components/modals/OkModal'
import SubscriptionAPIs from '@/api/admin/subscription-api'
import ErrorAlert from '@/@bya3/components/alerts/ErrorAlert'
import FormsService from '@/services/forms-service'
import ErrorHandler from '@/utils/error-handler'
import FEATURES, { PACKAGE_PERMISSIONS } from '@/constants/features'
import CustomFeaturesLimit from './CustomFeaturesLimit'
import PackagePermissions from './PackagePermissions'

export default {
  name: 'UpdateCustomPackageModal',
  components: {
    PackagePermissions,
    CustomFeaturesLimit,
    ErrorAlert,
    OkModal,
    ValidationObserver,
    BRow,
    BButton,
    BSpinner,
    BCol,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: [Number, String],
      default: null,
    },
    subscription: {
      type: Object,
      default: () => ({}),
    },
    renewMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        user_id: null,
        package_id: null,
        features: [],
        note: '',
        permissions: {},
      },
      customFeatures: {},
      loading: false,
      error: null,
    }
  },
  computed: {
    ...mapGetters({
      addons: 'pricing/addons',
      packageFeatures: 'pricing/packageFeatures',
    }),
  },
  watch: {
    subscription() {
      this.checkSubscription()
    },
  },
  mounted() {
    this.setPermission()
    this.setFeatures()
  },
  methods: {
    setPermission() {
      this.form.permissions = {}
      Object.values(PACKAGE_PERMISSIONS ?? {}).forEach(permission => {
        this.form.permissions[permission] = this.subscription?.package_permissions ? (this.subscription?.package_permissions[permission] ?? true) : true
      })
    },
    setFeatures() {
      const subFeatures = this.subscription?.features ?? []
      Object.values(FEATURES ?? {}).forEach(feature => {
        const index = subFeatures.findIndex(f => f.slug === feature)
        if (index > -1) this.customFeatures[feature] = { limit: subFeatures[index].limit }
      })
    },
    async submit() {
      const valid = await (this.$refs.form.validateWithInfo())
      if (!valid.isValid) {
        this.error = { title: this.$tc('errors.field-invalid', 2), message: ErrorHandler.getValidationErrors(valid.errors) }
        return
      }

      let payload = cloneDeep(this.form)
      payload.user_id = this.userId
      payload.features = Object.keys(this.customFeatures).reduce((prev, featureSlug) => {
        const featureId = this.packageFeatures.find(feature => feature.slug === featureSlug)?.id
        if (!featureId || (this.isQrPackage && featureSlug === 'device')) return { ...prev }
        return { ...prev, [featureId]: this.customFeatures[featureSlug] }
      }, {})
      payload = FormsService.convertObjectToFormData(payload)

      this.loading = true
      this.error = null

      SubscriptionAPIs.updateCustomSubscription(this.subscription.package_id, payload).then(response => {
        if (response.message) {
          this.$notifySuccess(response.message)
        }
        this.$emit('submitted')
        this.close()
      })
        .catch(error => { this.error = error })
        .finally(() => { this.loading = false })
    },
    close() {
      this.error = null
      this.$emit('input', false)
    },
  },
}
</script>
