<template>
  <ok-text-input
    :value="value"
    :rules="rules"
    :label="label || $t('subscription.price')"
    :read-only="disabled"
    @input="handleChange"
  >
    <template #append>
      <div class="bg-light-secondary px-1 d-flex justify-content-center align-items-center">
        ل.س
      </div>
    </template>
  </ok-text-input>
</template>

<script>
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'

export default {
  name: 'PriceInput',
  components: { OkTextInput },
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    allowZeroNumber: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    rules() {
      return this.allowZeroNumber ? 'required' : 'required|positive'
    },
  },
  methods: {
    handleChange(value) {
      this.$emit('input', value)
    },
  },
}
</script>
