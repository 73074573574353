<template>
  <vue-dropzone
    id="document-input-dropzone"
    ref="dropzone"
    :class="{disabled: disabled}"
    class="h-100"
    :style="{ minHeight: '140px' }"
    :options="dropzoneOptions"
    @vdropzone-file-added="handleFileAdded"
    @vdropzone-removed-file="handleFileRemove"
  />
</template>

<script>
import vueDropzone from 'vue2-dropzone'
import { API_URL } from '@/constants/config'
import MediaService from '@/services/media-service'
import { dropzoneBasicTemplate } from '@/@bya3/components/inputs/file-inputs/dropzone-templates'

export default {
  name: 'DocumentInputDropzone',
  components: {
    vueDropzone,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    maxFiles: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    acceptedFiles: {
      type: String,
      default: 'image/jpg, image/png, image/jpeg',
    },
  },
  data() {
    return {
      imagesCount: 0,
      currentSize: 0,
      dropzoneOptions: {
        url: `${API_URL}/images`,
        acceptedFiles: this.acceptedFiles,
        autoProcessQueue: false,
        thumbnailHeight: 100,
        maxFilesize: 20,
        maxFiles: this.maxFiles,
        previewTemplate: dropzoneBasicTemplate(false),
        dictDefaultMessage: this.$t('forms.drop-files-or-click'),
        transformFile(file, done) {
          if (!file?.type?.includes('image/')) {
            return done(file)
          }
          return MediaService.compressImage(file, 0.65)
            .then(res => done(res))
            .catch(() => done(file))
        },
      },
    }
  },
  mounted() {

  },
  methods: {
    handleFileAdded(file) {
      if (file.manuallyAdded) {
        return
      }
      const files = this.$refs.dropzone?.getAcceptedFiles ? this.$refs.dropzone.getAcceptedFiles() : []
      if (this.maxFiles === files.length && files.length >= 1) {
        this.$refs.dropzone.removeFile(files[0])
      }
      this.currentSize += file.size / 1000000
      this.imagesCount += 1
      setTimeout(() => (this.$refs.dropzone?.getAcceptedFiles ? this.$emit('input', this.$refs.dropzone.getAcceptedFiles()) : null), 200)
      this.$emit('remove', 0)
    },
    handleFileRemove(file) {
      this.imagesCount -= 1
      if (!file.manuallyAdded) {
        this.currentSize -= file.size / 1000000
      }
      setTimeout(() => (this.$refs.dropzone?.getAcceptedFiles ? this.$emit('input', this.$refs.dropzone.getAcceptedFiles()) : null), 200)
      this.$emit('remove', 1)
    },
    manuallyAddFile(url, name, size) {
      const file = {
        size, name, accepted: true, status: 'queued',
      }
      this.$refs.dropzone.manuallyAddFile(file, url, null, null, {
        dontSubstractMaxFiles: false,
        addToFiles: true,
      })
    },
  },
}
</script>

<style lang="scss">

</style>
