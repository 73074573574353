<template>
  <div>
    <ok-table
      ref="datatable"
      :title="$t('help.sections')"
      :config="config"
      @edit="handleEdit"
      @delete="handleDelete"
    >
      <template #buttons>
        <b-button
          variant="dark"
          size="md"
          @click="showModal=true, selectedSection =null"
        >
          <span class="align-middle"> {{ $t('help.add-section') }}</span>
        </b-button>
      </template>
    </ok-table>
    <process-guide-section
      v-model="showModal"
      :section="selectedSection"
      @processed="refreshTable"
    />
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import ProcessGuideSection from '@/views/admin/help/components/ProcessSection'
import HelpAPI from '@/api/admin/help-api'
import SectionsTableConfig from './config/sections-table-config'

export default {
  components: {
    ProcessGuideSection,
    BButton,
    OkTable: () => import(/* webpackChunkName: "ok-table" */ '@/@bya3/components/tables/ok-table/OkTable'),
  },
  data() {
    return {
      config: SectionsTableConfig,
      showModal: false,
      selectedSection: null,
    }
  },
  methods: {
    refreshTable() {
      this.showModal = false
      this.selectedSection = null
      this.$refs.datatable.loadItems()
    },
    handleEdit(props) {
      this.showModal = true
      this.selectedSection = props.row
    },
    handleDelete(data) {
      this.$okConfirm(this.$t('general.delete-confirm'), {
        preConfirm: () => HelpAPI.deleteSection(data.row.id)
          .then(response => {
            this.$notifySuccess(response.message)
            this.refreshTable()
          })
          .catch(e => this.$notifyError(e)),
      })
    },
  },
}
</script>
