<template>
  <div class="account-form">
    <fetching-data-spinner
      :loading="fetching"
      :error="fetchingError"
      :loading-title="$t('accounts.loading-account')"
    />

    <setup-wizard
      v-if="showForm"
      :tabs="tabs"
      :navigation="false"
      :edit-mode="true"
      @skip="$router.go(-1)"
    />
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import AccountsAPI from '@/api/admin/users'
import FetchingDataSpinner from '@/@bya3/components/utils/FetchingDataSpinner'
import SetupWizard from '@/@bya3/components/wizards/setup-wizard/SetupWizard'
import accountFormConfig from './config/account-form-config'

export default {
  components: {
    SetupWizard,
    FetchingDataSpinner,
  },
  data() {
    return {
      config: accountFormConfig,
      account: {},
      fetching: false,
      fetchingError: null,
    }
  },
  provide() {
    const accountDetails = {}

    Object.defineProperty(accountDetails, 'account', {
      enumerable: true,
      get: () => this.account,
      set: val => { this.account = val },
    })

    Object.defineProperty(accountDetails, 'subscription', {
      enumerable: true,
      get: () => this.account.subscription,
      set: val => { this.account.subscription = val },
    })

    return {
      accountDetails,
      reloadUser: debounce(this.getAccount, 500),
      setUser: this.setUser,
    }
  },
  computed: {
    id() {
      return this.$route?.params?.id
    },
    showForm() {
      return !this.fetching && !this.fetchingError
    },
    tabs() {
      const { tabs } = cloneDeep(this.config)
      if (this.id) tabs[1].hide = false
      return tabs
    },
  },
  mounted() {
    this.getAccount()
  },
  methods: {
    getAccount() {
      this.fetching = true
      this.fetchingError = null
      const fetchPackagesAndFeatures = this.$store.dispatch('subscription/FETCH_PRICING')
      const promises = this.id ? [AccountsAPI.getProfile(this.id), fetchPackagesAndFeatures] : [fetchPackagesAndFeatures]

      Promise.all(promises)
        .then(([account]) => {
          this.account = this.id ? account : this.account
        })
        .catch(e => { this.fetchingError = e })
        .finally(() => { this.fetching = false })
    },
    setUser(account) {
      if (!account) return
      this.fetching = true
      this.account = account
      this.$nextTick(() => { this.fetching = false })
    },
  },
}
</script>
