import queryString from 'query-string'

class ImpersonateService {
    public static inImpersonateMode = false;

    private static popupWin: any = null;

    public static openWindow(url: string, name: string, args: string) {
      if (this.popupWin === null || typeof this.popupWin !== 'object') {
        this.popupWin = window.open(url, name, args)
      } else if (!this.popupWin.closed) {
        this.popupWin.location.href = url
      } else {
        this.popupWin = window.open(url, name, args)
      }

      if (this.popupWin.focus) {
        this.popupWin.focus()
      }

      return this.popupWin
    }

    public static getAccessTokenFromLocation() {
      const search = window?.location?.search
      if (!search) return null
      if (queryString.parse(search)?.access_token) {
        this.inImpersonateMode = true
      }
      return queryString.parse(search)?.access_token ?? null
    }

    public static getFromQueryStringKey() {
      const search = window?.location?.search
      if (!search) return 'browser'
      return queryString.parse(search)?.from ?? 'browser'
    }
}

export default ImpersonateService
