<template>
  <ok-text-input
    :value="value"
    :label="$t('subscription.billing-cycle')"
    is-required
  >
    <b-form-select
      :value="value"
      :disabled="isDisabled"
      :options="options"
      @input="$emit('input', $event)"
    />
  </ok-text-input>
</template>

<script>
import { BFormSelect } from 'bootstrap-vue'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'
import AddonCycleEnum from '@/models/subscription/AddonCycleEnum'

export default {
  name: 'BillingCycleSelectDropdown',
  components: { OkTextInput, BFormSelect },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    addon: {
      type: Object,
      default: null,
    },
    showCustom: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isDisabled() {
      return this.addon?.cycle === AddonCycleEnum.ONE_TIME || this.disabled
    },
    options() {
      const monthlyOption = { text: this.$t('subscription.billed-monthly'), value: 1 }
      const yearlyOption = { text: this.$t('subscription.billed-annually'), value: 0 }
      const base = this.showCustom ? [{ text: this.$t('subscription.custom'), value: 2 }] : []

      if (this.addon?.cycle === AddonCycleEnum.MONTHLY) {
        return [monthlyOption, ...base]
      }

      if (this.addon?.cycle === AddonCycleEnum.YEARLY) {
        return [yearlyOption, ...base]
      }

      return [monthlyOption, yearlyOption, ...base]
    },
  },
  watch: {
    options(options) {
      if (options.length === 1) {
        this.$emit('input', options[0].value)
      }
    },
  },
}
</script>
