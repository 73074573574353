<template>
  <div>
    <ok-table
      ref="datatable"
      :config="config"
      :title="$t('menu.business-types')"
      @delete="handleDelete"
      @edit="handleEdit"
    >
      <template slot="buttons">
        <b-button
          size="md"
          variant="dark"
          class="mx-md-1"
          @click="handleEdit"
        >
          {{ $t('assistants.add') }}
        </b-button>
      </template>
      <template #name-column="{props}">
        {{ getName(props.row) }}
      </template>
    </ok-table>
    <process-type-modal
      v-model="showModal"
      :type="selectedType"
      @updated="refreshTable"
    />
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import BusinessTypesAPI from '@/api/admin/business-types-api'
import LocalizationService from '@/services/localization-service'
import ProcessTypeModal from '@/views/admin/business-types/components/ProcessTypeModal'
import TypesTableConfig from './config/types-table-config'

export default {
  name: 'BusinessTypesTable',
  components: {
    ProcessTypeModal,
    BButton,
    OkTable: () => import(/* webpackChunkName: "ok-table" */ '@/@bya3/components/tables/ok-table/OkTable'),
  },
  data() {
    return {
      config: TypesTableConfig,
      selectedType: {},
      showModal: false,
    }
  },
  methods: {
    refreshTable() {
      this.selectedType = {}
      this.showModal = false
      this.$refs.datatable.loadItems()
    },
    handleEdit(data = null) {
      this.selectedType = data?.row ?? null
      this.showModal = true
    },
    handleDelete(data) {
      this.$okConfirm(this.$t('general.delete-confirm'), {
        preConfirm: () => BusinessTypesAPI.delete(data.row.id)
          .then(response => {
            this.$notifySuccess(response.message)
            this.refreshTable()
          })
          .catch(e => this.$notifyError(e)),
      })
    },
    getName(item) {
      return LocalizationService.getTranslateArEn(item)
    },
  },
}
</script>
