import OkTableConfigType from '@/@bya3/components/tables/ok-table/OkTableConfigType'
import { API_URL } from '@/constants/config'
import CommonTableActions from '@/constants/common-table-actions'

const TypesTableConfig: OkTableConfigType = {
  mode: 'remote',
  url: `${API_URL}/admin/business-types`,
  rowsPath: 'types',
  rowClickable: true,
  columns: [
    {
      label: 'fields.name',
      field: 'name',
    },
    {
      label: 'fields.slug',
      field: 'slug',
    },
  ],
  paginationOptions: {
    enabled: false,
  },
  sortOptions: {
    enabled: true,
  },
  actions: [
    ...CommonTableActions,
  ],
  filters: [
  ],
}

export default TypesTableConfig
