<template>
  <v-select
    :value="value"
    class="business-types-select"
    :class="{'is-invalid': state === false, 'select-size-lg': size === 'lg', 'select-size-sm': size === 'sm'}"
    :dir="isRTL ? 'rtl' : 'ltr'"
    :options="options"
    label="name"
    :clearable="clearable"
    :reduce="t => t.id"
    :loading="loading"
    max-height="100px"
    @input="$emit('input', $event)"
  />
</template>

<script>
import vSelect from 'vue-select'
import LocalizationService from '@/services/localization-service'
import HelpAPI from '@/api/admin/help-api'

export default {
  name: 'GuideCategorySelect',
  components: { vSelect },
  props: {
    value: {
      type: Number,
      default: null,
    },
    onlyParent: {
      type: Boolean,
      default: false,
    },
    onlyChild: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      options: [],
    }
  },
  computed: {
    locale() {
      return this.$i18n.locale
    },
  },
  async mounted() {
    await this.getList()
  },
  methods: {
    async getList() {
      this.loading = true
      await HelpAPI.categoriesList(this.onlyChild, this.onlyParent).then(res => {
        console.log(res)
        this.options = res.map(b => ({ ...b, name: LocalizationService.getTranslateArEn(b) }))
      }).catch(e => this.$notifyError(e)).finally(() => { this.loading = false })
    },
  },
}
</script>
