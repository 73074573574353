import OkTableConfigType from '@/@bya3/components/tables/ok-table/OkTableConfigType'
import { API_URL } from '@/constants/config'
import CommonTableActions from '@/constants/common-table-actions'

const SectionsTableConfig: OkTableConfigType = {
  mode: 'remote',
  url: `${API_URL}/admin/guide/sections`,
  rowsPath: 'sections',
  rowClickable: true,
  columns: [
    {
      label: 'fields.slug',
      field: 'slug',
    },
    {
      label: 'help.section-title',
      field: 'ar_title',
    },
    {
      label: 'help.order',
      field: 'order',
    },
    {
      label: 'store.category',
      field: 'category',
      formatFn: (val: any) => val.ar_name,
    },
  ],
  paginationOptions: {
    enabled: true,
  },
  sortOptions: {
    enabled: false,
  },
  actions: [
    ...CommonTableActions,
  ],
  filters: [
  ],
}

export default SectionsTableConfig
