<template>
  <div class="select-addon-radio">
    <ok-text-input
      #default="{ errors }"
      :value="addonId"
      :label="$t('subscription.addon')"
      label-class="text-uppercase"
      is-required
      rules="required"
    >
      <b-form-select
        v-if="type==='select'"
        :value="addonId"
        :options="options"
        :state="errors.length ? false : null"
        @input="handleSelectAddon"
      />
      <b-form-radio-group
        v-else
        :value="addonId"
        :options="options"
        :state="errors.length ? false : null"
        @input="handleSelectAddon"
      />

    </ok-text-input>
  </div>
</template>

<script>
import { BFormRadioGroup, BFormSelect } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'
import LocalizationService from '@/services/localization-service'

export default {
  name: 'SelectAddonRadio',
  components: {
    OkTextInput,
    BFormRadioGroup,
    BFormSelect,
  },
  props: {
    value: {
      type: [Number, String, Object],
      default: null,
    },
    exceptAddons: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'radio',
    },
  },
  computed: {
    ...mapGetters({
      addons: 'pricing/addons',
    }),
    addonId() {
      return this.value?.id ?? this.value
    },
    options() {
      const addons = this.addons.filter(addon => {
        if (this.type === 'select' && this.addonId) {
          return this.addonId === addon.id || !this.exceptAddons.includes(addon.slug)
        }
        return !this.exceptAddons.includes(addon.slug)
      })
      return addons.map(addon => ({ text: LocalizationService.getTranslate(addon), value: addon.id }))
    },
  },
  methods: {
    handleSelectAddon(addonId) {
      const selectedAddon = this.addons.find(addon => addon.id === addonId)
      if (this.type === 'select') {
        this.$emit('input', addonId)
        this.$emit('addon', selectedAddon)
      } else {
        this.$emit('input', selectedAddon)
      }
    },
  },
}
</script>
