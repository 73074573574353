<template>
  <div class="statistics-range">
    <div class="d-flex flex-wrap">
      <b-dropdown
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        :text="selectedPeriod ? selectedPeriod.text : $t('statistics.periods.custom-range')"
        class="branches-dropdown mb-1 mb-md-0 flex-grow-1 flex-md-grow-0"
        variant="outline-secondary"
      >
        <template v-for="(period, idx) in periods">
          <b-dropdown-item-button
            :key="idx"
            button-class="w-100"
            :active="selectedPeriod && period.text === selectedPeriod.text"
            @click="setPeriod(period)"
          >
            {{ period.text }}
          </b-dropdown-item-button>
        </template>

        <b-dropdown-divider />

        <b-dropdown-item-button
          button-class="w-100"
          :active="selectedPeriod === null"
          @click="setPeriod()"
        >
          {{ $t('statistics.periods.custom-range') }}
        </b-dropdown-item-button>
      </b-dropdown>

      <div
        v-if="!selectedPeriod"
        class="ml-1 d-flex align-items-center"
      >
        <div class="to-label">
          {{ $t('general.from') }}
        </div>
        <ok-date-time-picker
          v-model="customFrom"
          hide-label
          @input="handleCustomChange"
        />
        <div class="to-label">
          {{ $t('general.to') }}
        </div>
        <ok-date-time-picker
          v-model="customTo"
          hide-label
          @input="handleCustomChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { BDropdown, BDropdownItemButton, BDropdownDivider } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import OkDateTimePicker from '@/@bya3/components/inputs/OkDateTimePicker'

export default {
  name: 'StatisticsRange',
  components: {
    OkDateTimePicker,
    BDropdown,
    BDropdownItemButton,
    BDropdownDivider,
  },
  directives: {
    Ripple,
  },
  props: {
    customPeriods: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedPeriod: null,
      customFrom: this.$moment('2020-12-01').format('YYYY-MM-DD'),
      customTo: this.$moment().format('YYYY-MM-DD'),
    }
  },
  computed: {
    periods() {
      if (this.customPeriods?.length) return this.customPeriods
      return [
        { text: this.$t('statistics.periods.2-years'), last: '2years' },
        { text: this.$t('statistics.periods.last-year'), last: 'year' },
        { text: this.$t('statistics.periods.today'), last: 'today' },
        { text: this.$t('statistics.periods.yesterday'), last: 1 },
        { text: this.$t('statistics.periods.last-days', [7]), last: 7 },
        { text: this.$t('statistics.periods.last-days', [30]), last: 30 },
        { text: this.$t('statistics.periods.last-month'), last: 'month' },
      ]
    },
  },
  mounted() {
    [this.selectedPeriod] = this.periods
  },
  methods: {
    setPeriod(period = null) {
      this.selectedPeriod = period
      if (period) {
        this.$emit('custom-period', period?.last)
      }
    },
    handleCustomChange() {
      if (this.customFrom && this.customTo) {
        this.$emit('custom-period', null)
        this.$emit('input', { fromDate: this.customFrom, toDate: this.customTo })
      }
    },
  },
}
</script>

<style lang="scss">
.statistics-range {
  .form-group {
    margin-bottom: 0!important;
  }

  .to-label {
    padding: 0 4px;
  }
}
</style>
