<template>
  <ok-modal
    :value="value"
    size="lg"
    :title="$t('accounts.add-addon')"
    @change="$event ? null : close()"
  >
    <error-alert :error="error" />

    <validation-observer
      ref="form"
      #default="{invalid}"
      tag="div"
    >
      <div v-if="activeAddons.length">
        <label class="h5 mb-1"> Active Addons</label>
        <b-row
          v-for="add in activeAddons"
          :key="add.id"
        >
          <b-col
            md="3"
            style="margin-top:5px"
          >
            {{ getAddonName(add) }}
          </b-col>
          <b-col
            md="3"
            style="margin-top:5px"
          >
            {{ getAddonBranch(add) }}
          </b-col>
          <b-col
            md="3"
            style="margin-top:5px"
          >
            {{ add.pivot_expiry || '---------' }}
          </b-col>
          <b-col
            md="3"
            style="margin-top:5px"
          >
            {{ add.pivot_price }}$
          </b-col>
        </b-row>
        <hr>
      </div>
      <select-addon-radio
        v-model="addon"
        :except-addons="exceptAddons"
        @input="form.limit = 1"
      />

      <b-row v-if="addon && addon.id">
        <b-col
          v-if="branchAddon"
          md="6"
        >
          <ok-text-input
            label="Select branch"
            is-required
          >
            <b-form-select
              v-model="form.branch_id"
            >
              <b-form-select-option
                v-for="branch in branches"
                :key="branch.id"
                :value="branch.id"
              >
                {{ branch.name }}
              </b-form-select-option>
            </b-form-select>
          </ok-text-input>
        </b-col>
        <b-col
          v-if="addon && showLimitInput"
          md="6"
        >
          <ok-text-input
            v-model="form.limit"
            :label="$t('fields.count')"
            type="number"
          >

            <b-form-spinbutton
              v-model="form.limit"
              placeholder="--"
              min="1"
            />
          </ok-text-input>
        </b-col>

        <b-col md="6">
          <billing-cycle-select-dropdown
            v-model="form.billing"
            :addon="addon"
            @input="setPrice()"
          />
        </b-col>

        <b-col
          v-if="form.paid!==3"
          md="6"
        >
          <price-input
            v-model.trim="form.price"
            :label="$t('subscription.price') + (showLimitInput ? ' (Per unit)' : '')"
          />
        </b-col>
        <b-col
          v-if="form.paid===3"
          md="12"
        >
          <ok-text-input
            v-model="form.reason"
            is-required
            :label="$t('grace.reason')"
            textarea
          />
        </b-col>
        <b-col md="12">
          <ok-text-input
            v-model="form.note"
            :label="$t('accounts.notes')"
            textarea
          />
        </b-col>
      </b-row>

      <div class="d-flex justify-content-end align-items-center mt-1">
        <b-button
          size="lg"
          variant="secondary"
          :disabled="invalid || !addon || loading"
          @click="submit"
        >
          <b-spinner
            v-if="loading"
            small
          />
          <template v-else>
            {{ $t('forms.add') }}
          </template>
        </b-button>
      </div>
    </validation-observer>
  </ok-modal>
</template>

<script>
import {
  BRow, BCol, BButton, BSpinner, BFormSelect, BFormSelectOption, BFormSpinbutton,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import cloneDeep from 'lodash/cloneDeep'
import OkModal from '@/@bya3/components/modals/OkModal'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'
import ErrorAlert from '@/@bya3/components/alerts/ErrorAlert'
import FormsService from '@/services/forms-service'
import LocalizationService from '@/services/localization-service'
import PriceInput from '@/@bya3/components/inputs/PriceInput'
import SubscriptionAPIs from '@/api/admin/subscription-api'
import BillingCycleSelectDropdown from '@/views/admin/users/components/BillingCycleSelectDropdown'
import SelectAddonRadio from './SelectAddonRadio'

export default {
  name: 'AddAddonModal',
  components: {
    BillingCycleSelectDropdown,
    ErrorAlert,
    OkTextInput,
    PriceInput,
    SelectAddonRadio,
    OkModal,
    ValidationObserver,
    BRow,
    BButton,
    BSpinner,
    BCol,
    BFormSelect,
    BFormSelectOption,
    BFormSpinbutton,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: [Number, String],
      default: null,
    },
    subscription: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      form: {
        billing: 1,
        price: null,
        paid: 0,
        note: '',
        branch_id: null,
        grace_period: 0,
        limit: 1,
      },
      addon: null,
      image: null,
      loading: false,
      error: null,
      branches: [],
      purchasedAddons: [],
      addonsByBranch: ['google', 'branded_domain', 'whatsapp', 'subcashier', 'ok_pos'],
    }
  },
  computed: {
    exceptAddons() {
      return (this.subscription.addons ?? []).filter(add => add.cycle === 3).map(addon => addon.slug)
    },
    activeAddons() {
      return (this.subscription.addons ?? []).filter(add => !add.expired)
    },
    branchAddon() {
      if (!(this.addon?.slug ?? null)) return false
      return this.addonsByBranch.includes(this.addon.slug)
    },
    showLimitInput() {
      return this.$store.getters['purchase/isAddonHasCount'](this.addon.slug)
    },
  },
  watch: {
    userId() {
      this.getUserBranchesAndAddons()
    },
    addon() {
      this.setPrice()
    },
  },
  mounted() {
    this.getUserBranchesAndAddons()
  },
  methods: {
    setPrice() {
      this.form.price = this.getAddonPrice() ?? this.form.price
    },
    getAddonPrice() {
      if (!this.addon?.id) {
        return null
      }

      const addons = this.$store.getters['pricing/addons']
      const index = addons.findIndex(bn => bn.id === this.addon.id)

      if (index < 0) {
        return null
      }

      return (this.form.billing === 1 ? (addons[index]?.monthly_price ?? 0) : (addons[index]?.yearly_price ?? 0))
    },
    getUserBranchesAndAddons() {
      this.branches = []
      this.purchasedAddons = []
      SubscriptionAPIs.getUserBranchesAddons(this.userId)
        .then(response => {
          if (response.error === 0) {
            this.branches = response.branches
            if (this.branches.length) this.form.branch_id = this.branches[0].id
            this.purchasedAddons = response.addons
          } else {
            this.$notifySuccess(response.message)
          }
        })
        .catch(error => { this.error = error })
        .finally(() => { this.loading = false })
    },
    submit() {
      if (!this.addon) return

      let payload = cloneDeep(this.form)
      payload.user_id = this.userId
      payload.features = [
        {
          id: this.addon.id,
          cycle: this.form.billing === 1 ? 0 : 1,
          branch_id: this.form.branch_id,
          price: this.form.price,
        },
      ]
      if (this.$store.getters['purchase/isAddonHasCount'](this.addon.slug)) {
        payload.features[0].limit = this.form.limit
      }
      if (this.form.paid && this.image) {
        payload.image = this.image
      }
      payload.price = 0
      // eslint-disable-next-line no-underscore-dangle
      payload._method = 'put'
      payload = FormsService.convertObjectToFormData(payload)

      this.loading = true
      this.error = null

      SubscriptionAPIs.addAddons(payload)
        .then(response => {
          this.$notifySuccess(response.message)
          this.branches = []
          this.purchasedAddons = []
          this.$emit('submitted')
          this.close()
        })
        .catch(error => { this.error = error })
        .finally(() => { this.loading = false })
    },
    close() {
      this.error = null
      this.$emit('input', false)
    },
    getAddonName(addon) {
      return LocalizationService.getTranslate(addon)
    },
    getAddonBranch(addon) {
      if (!this.addonsByBranch.includes(addon.slug)) return 'App scope'
      if (!addon.branch_id) return '-'
      const index = this.branches.findIndex(bn => bn.id === addon.branch_id)
      if (index > -1) return this.branches[index].name
      return '-'
    },
  },
}
</script>
