<template>
  <div class="mb-1">
    <div class="font-weight-semi-bold mb-1">
      {{ $t("accounts.permissions") }}
    </div>
    <div class="pl-1 border-left-secondary border-left-2">
      <b-row>
        <template v-for="(permission, idx) in package_permissions">
          <b-col

            :key="idx"
            md="4"
            sm="4"
            class="mb-1"
          >
            <b-form-checkbox v-model="permissions[permission.value]">
              {{ permission.text }}
            </b-form-checkbox>
          </b-col>
        </template>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BFormCheckbox, BRow, BCol,
} from 'bootstrap-vue'
import capitalize from 'lodash/capitalize'
import { PACKAGE_PERMISSIONS, PACKAGE_FEATURES } from '@/constants/features'

export default {
  name: 'PackagePermissions',
  components: {
    BFormCheckbox,
    BRow,
    BCol,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    isCustom: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      package_permissions: PACKAGE_PERMISSIONS,

    }
  },
  computed: {
    permissions: {
      get() { return this.value ?? {} },
      set(val) { this.$emit('input', val) },
    },
  },
  mounted() {
    this.formatPermission()
  },
  methods: {
    formatPermission() {
      this.package_permissions = this.value

      this.package_permissions = Object.values(this.isCustom ? PACKAGE_PERMISSIONS : PACKAGE_FEATURES)
        .map(permission => ({ value: permission, text: capitalize(permission.replace('_', ' ')) }))
    },
  },
}
</script>
