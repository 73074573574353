<template>
  <div class="setup-card">
    <div class="setup-card-header">
      <div class="d-flex flex-wrap justify-content-between align-items-center w-100">
        <div class="setup-card-header-title">
          {{ title }}
        </div>
        <div>
          <slot name="actions" />
        </div>
      </div>
    </div>
    <div class="setup-card-body">
      <slot name="body" />
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  name: 'SetupCard',
  components: {
  },
  props: {
    title: {
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/utils/responsive.scss";
// @import "@/@core/scss/base/components/_variables-dark.scss";

.setup-card {
  &-header {
    &-title {
      font-size: 25px;
      line-height: 1.2;
      color: var(--purple);
      font-weight: 700;
      body.dark-layout & {
        color: #ffffff;
      }
      margin-bottom: 10px!important;
      @include respond-to('small') {
        margin-bottom: 0!important;
      }

      padding-right: 20px;

      [dir=rtl] & {
        padding-left: 20px;
      }
    }
    padding-bottom: 21.5px;
    border-bottom: 2px solid var(--seperator-color);
  }

  .action-button {
    padding: 0.8rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 46px;
  }

  .skip-button {
    font-size: 16px!important;
    font-weight: 500;
    line-height: 1.19;
    padding: 14px 8px 13px!important;
  }

  @include respond-to('large') {
    .action-button {
      min-width: 98px;
      font-size: 16px;
      padding: 14px;
      &.next-small-button{
        min-width: 98px;
        font-size: 16px;
      }
    }
    .skip-button {
      min-width: 80px!important;
    }
  }

  &-body {
    padding-top: 21.5px;

    .setup-description ,.setup-step-description {
      padding-bottom: 25px;
      line-height: 1.71;
      font-weight: 500;
      color: var(--description-color);
    }

    .setup-description-question {
      padding-bottom: 29px;
      font-size: 25px;
      font-weight: 600;
      line-height: 1.4;
      text-align: left;
      color: var(--purple);
    }
  }
}
</style>
