<template>
  <b-card
    class="statistics-counts-card mb-1"
    body-class="p-0"
  >
    <div class="title">
      {{ title }}
    </div>

    <b-row>
      <b-col
        v-for="(count, countIdx) in counts"
        :key="countIdx"
        :md="12/counts.length"
        :sm="6"
        :cols="6"
      >
        <div class="count">
          <span class="value">{{ count.value }}</span>
          <span class="append">{{ count.append }}</span>
        </div>
        <div class="count-description text-nowrap">
          {{ count.description }}
        </div>
      </b-col>
    </b-row>

    <div class="summary d-flex align-items-center">
      <img
        v-if="icon"
        height="21"
        :src="icon"
        alt="Icon"
      >

      <div>
        <span class="total-label">
          {{ totalText }}:
        </span>
        <span class="total-value">
          {{ total }}
        </span>
        <span class="total-append">
          {{ totalAppend }}
        </span>
        <feather-icon
          v-if="titleIcon"
          :icon="titleIcon"
          size="20"
          class="ml-50 statistics-title-icon"
          :class="titleIcon"
        />
      </div>
    </div>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'

export default {
  name: 'StatisticsGroupedCountsCard',
  components: {
    BCard, BRow, BCol,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    counts: {
      type: Array,
      default: () => ([]),
    },
    icon: {
      type: String,
      default: '',
    },
    totalText: {
      type: String,
      default: '',
    },
    total: {
      type: [String, Number],
      default: '',
    },
    totalAppend: {
      type: String,
      default: '',
    },
    titleIcon: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss">
.statistics-counts-card {
  padding: 16px 24px;

  .title {
    color: #5e5873;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    margin-bottom: 18px;
  }

  .count {
    .value {
      font-size: 27px;
      font-weight: 500;
      line-height: 1.41;
      color: #5e5873;
    }

    .append {
      font-size: 12px;
      font-weight: 600;
      line-height: 1.42;
      color: #5e5873;
    }
  }

  .count-description {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.42;
    color: var(--description-color);
  }

  .summary {
    margin-top: 17.5px;

    img {
      margin-right: 9.6px;
    }

    .total-label, .total-value, .total-append {
      color: #aeabba;
      font-size: 14px;
      line-height: 17px;
    }

    .total-label {
      font-weight: 300;
    }

    .total-value {
      font-weight: 600;
    }

    .total-append {
      font-size: 8px;
      line-height: 2.13;
    }
  }
}
</style>
