<template>
  <setup-card :title="$t(title)">
    <template #actions>
      <div class="d-flex flex-nowrap">
        <slot
          name="actions"
          v-bind="{loading, variant, nextButtonClass, isRTL, next, prev, skip}"
        >
          <b-button
            v-if="showPrevious"
            v-ripple.400="'rgba(0, 0, 0, 0.15)'"
            :variant="`outline-${variant}`"
            class="mr-1 action-button prev-button"
            size="lg"
            @click="prev"
          >
            <span class="d-none d-md-inline">
              {{ $t('forms.prev') }}
            </span>
            <feather-icon
              class="d-inline d-md-none"
              :icon="isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
            />
          </b-button>
          <b-button
            v-if="showSkip"
            v-ripple.400="'rgba(0, 0, 0, 0.15)'"
            :variant="`outline-${variant}`"
            class="mr-1 action-button skip-button"
            size="lg"
            @click="skip"
          >
            <span class="d-none d-md-inline">
              {{ skipButtonTitle || $t('forms.skip') }}
            </span>
            <feather-icon
              class="d-inline d-md-none"
              icon="SkipForwardIcon"
            />
          </b-button>
          <b-button
            v-if="!hideNext"
            v-ripple.400="'rgba(0, 0, 0, 0.15)'"
            :variant="variant"
            :disabled="disabledNextButton || loading"
            class="action-button next-button"
            :class="nextButtonClass"
            size="lg"
            @click="next"
          >
            <span>
              {{ nextButtonTitle }}
            </span>
            <b-spinner
              v-if="loading"
              class="ml-1"
              small
              label="submitting..."
            />
          </b-button>
        </slot>
      </div>
    </template>
    <template #body>
      <slot name="alert" />
      <div
        v-if="description"
        class="setup-step-description"
        v-text="$t(description)"
      />
      <slot />
    </template>
  </setup-card>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useAppConfig from '@core/app-config/useAppConfig'
import SetupCard from '@/@bya3/components/wizards/setup-wizard/SetupCard.vue'
import i18n from '@/libs/i18n'

export default {
  name: 'SetupWizardTab',
  components: {
    SetupCard, BButton, BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    showPrevious: {
      type: Boolean,
      default: false,
    },
    nextButtonTitle: {
      type: String,
      default: () => i18n.t('forms.next'),
    },
    skipButtonTitle: {
      type: String,
      default: () => i18n.t('forms.skip'),
    },
    disabledNextButton: {
      type: [Boolean, Number],
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: '',
    },
    hideNext: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    nextButtonClass: {
      type: String,
      default: '',
    },
    narrowCard: {
      type: Boolean,
      default: false,
    },
    showSkip: {
      type: Boolean,
      default: false,
    },
    nextHandler: {
      type: Function,
      default: null,
    },
    prevHandler: {
      type: Function,
      default: null,
    },
    skipHandler: {
      type: Function,
      default: null,
    },
  },
  setup() {
    const { skin } = useAppConfig()

    return { skin }
  },
  computed: {
    variant() {
      return this.isDark ? 'light' : 'dark'
    },
  },
  methods: {
    skip() {
      return this.skipHandler ? this.skipHandler() : this.$emit('skip')
    },
    prev() {
      return this.prevHandler ? this.prevHandler() : this.$emit('prev')
    },
    next() {
      return this.nextHandler ? this.nextHandler() : this.$emit('next')
    },
  },
}
</script>
