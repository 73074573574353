<template>
  <v-select
    ref="select"
    :class="{'select-size-lg': size === 'lg', 'select-size-sm': size === 'sm'}"
    :clearable="false"
    :dir="isRTL ? 'rtl' : 'ltr'"
    :disabled="disabled"
    :multiple="multiple"
    :options="filteredOptions"
    :placeholder="$t('forms.language-ph')"
    :reduce="option => option.id"
    :value="value"
    autocomplete="off"
    class="language-select"
    label="label"
    @input="$emit('input', $event)"
  />
</template>

<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import locales from '@/constants/locales'

export default {
  name: 'LocalizationLanguageSelect',
  components: {
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    value: {
      type: [String, Array],
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    withUi: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    all: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    options() {
      if (this.all) return locales.map(locale => ({ ...(locale ?? {}), label: this.$t(`locales.${locale.id}`) }))
      const localesKeys = this.$store.getters['auth/locales']
      return locales.filter(locale => localesKeys.includes(locale.id)).map(locale => ({
        ...(locale ?? {}),
        label: this.$t(`locales.${locale.id}`),
      }))
    },
    filteredOptions() {
      if (this.withUi) return this.options
      const options = this.options.filter(locale => locale.id !== this.$i18n.locale)
      return options.length ? options : [this.$i18n.locale]
    },
  },
  watch: {
    options(options) {
      this.$emit('update:options-count', options.length)
    },
    filteredOptions(options) {
      if (options.length && options.findIndex(option => option.id === this.value) < 0) {
        this.$emit('input', options[0]?.id)
      }
    },
  },
  mounted() {
    this.$emit('update:options-count', this.options.length)
  },
  methods: {
    handleEmitNewLanguage() {
      this.$emit('add-language')
      setTimeout(() => this.$refs.select.closeSearchOptions(), 200)
    },
  },
}
</script>

<style lang="scss">
.language-select {
  ul {
    overflow-x: hidden;
  }

  .add-new-language-button {
    font-size: 11px !important;
    padding: 8px 20px !important;
  }
}
</style>
