<template>
  <div>
    <ok-table
      ref="datatable"
      :title="$t('store.categories')"
      :config="tableConfig"
      @edit="handleEdit"
      @delete="handleDelete"
    >
      <template #buttons>
        <b-button
          variant="dark"
          size="md"
          @click="showModal=true, selectedQuestion =null"
        >
          <span class="align-middle"> {{ $t('help.add-category') }}</span>
        </b-button>
      </template>
    </ok-table>
    <ok-confirm-modal
      v-if="deleteQuestion"
      :show-cancel="true"
      @confirm="removeQuestion"
      @cancel="deleteQuestion=null"
    />
    <process-guide-category-modal
      v-model="showModal"
      :category="selectedQuestion"
      @processed="refreshTable"
    />
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import cloneDeep from 'lodash/cloneDeep'
import OkConfirmModal from '@/@bya3/components/modals/OkConfirmModal'
import HelpAPI from '@/api/admin/help-api'
import ProcessGuideCategoryModal from '@/views/admin/help/components/ProcessCategories'
import QuestionsTableConfig from './config/questions-table-config'

export default {
  components: {
    ProcessGuideCategoryModal,
    OkConfirmModal,
    BButton,
    OkTable: () => import(/* webpackChunkName: "ok-table" */ '@/@bya3/components/tables/ok-table/OkTable'),
  },
  data() {
    return {
      showModal: false,
      selectedQuestion: null,
      deleteQuestion: null,
    }
  },
  computed: {
    tableConfig() {
      return cloneDeep(QuestionsTableConfig)
    },
  },
  methods: {
    refreshTable() {
      this.selectedQuestion = null
      this.showModal = false
      this.$refs.datatable.loadItems()
    },
    handleEdit(props) {
      this.showModal = true
      this.selectedQuestion = props.row
    },
    handleDelete(props) {
      this.deleteQuestion = props.row.id
    },
    removeQuestion() {
      HelpAPI.delete(this.deleteQuestion)
        .then(response => {
          if (!response.error) {
            this.$notifySuccess(response.message)
            this.refreshTable()
            this.deleteQuestion = null
          } else this.$notifyError(response.message)
        })
        .catch(error => this.$notifyError(error))
    },
  },
}
</script>
